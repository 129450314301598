Page = require "./Page.coffee"
Utils = require "../utils/Utils.coffee"

class OutroPage extends Page
	constructor:()->
		super


	onLoaded:(rq)=>
		super

		@outroButton = @element.querySelector("#replayButton")
		@outroButton.addEventListener("click", ()=>
			document.location.reload()
			)

		@element.querySelector("#facebookShare").addEventListener "click", @onFacebookClick
		@element.querySelector("#twitterShare").addEventListener "click", @onTwitterClick

	onFacebookClick:()=>

		url = "https://www.facebook.com/dialog/share?"
		url += "app_id=1537691443188529"
		url += "&display=popup"
		url += "&href=#{encodeURIComponent('http://mmorph.massivemusic.com/')}"
		url += "&redirect_uri=#{encodeURIComponent('http://www.facebook.com/')}"
 

		@openPopup(url, 320, 560)

	onTwitterClick:()=>

		tweetText = "#mmorph is an adventure into new ways of delivering interactive music in the browser and beyond."
		
		url = "https://www.twitter.com/intent/tweet?"		
		url += "&text=#{encodeURIComponent(tweetText)}"
		url += "&url=#{encodeURIComponent('http://mmorph.massivemusic.com/')}" 

		@openPopup(url, 320, 560)


	openPopup:(url, h, w)=>

		dualScreenLeft = screen.left
		if typeof(window.screenLeft) != "undefined"
			dualScreenLeft = window.screenLeft

		dualScreenTop = screen.top
		if typeof(window.screenTop) != "undefined"
			dualScreenTop = window.screenTop

		width = window.innerWidth
		height = window.innerHeight

		left = ((width / 2) - (w / 2)) + dualScreenLeft
		top = ((height / 2) - (h / 2)) + dualScreenTop;

		window.open(url, "", "width=#{w}, height=#{h}, top=#{top}, left=#{left}")

	show:()=>
		super

		# re-enable pointer

		document.body.style.cursor = "default"

		setTimeout(()=>
			@element.classList.add("visible")
		,100)

	hide:()=>
		

		@element.classList.remove("visible")

		setTimeout(()=>
			super
			@emit("hide", null)	
		,800)
		



module.exports = OutroPage