

SongData = {}

SongData.types = {
	TOGGLE : "typeToggle"
	CUE : "typeCue"
	FX : "typeFx"
}

SongData.sections = {

	"a1" : {
		"top" : [ 
			{
				"id" : "A-bass-on"
				"type" : SongData.types.TOGGLE
				"listen" : "A-bass-playing"
				"label" : "Bass"
				"icon" : "bass"
				"selected" : false
			}
			{
				"id" : "A-perc-on"
				"type" : SongData.types.TOGGLE
				"listen" : "A-perc-playing"
				"label" : "Drums"
				"icon" : "drums"
				"selected" : false
			}
			{
				"id" : "A-synths-on"
				"type" : SongData.types.TOGGLE
				"listen" : "A-synths-playing"
				"label" : "Synthesiser"
				"icon" : "synths"
				"selected" : false
			}
			{
				"id" : "A-uke-on"
				"type" : SongData.types.TOGGLE
				"listen" : "A-uke-playing"
				"label" : "Ukelele"
				"icon" : "uke"
				"selected" : false
			}
			{
				"id" : "A-vox-on"
				"type" : SongData.types.TOGGLE
				"listen" : "A-vox-playing"
				"label" : "Vocals"
				"icon" : "vocals"
				"selected" : false
			}
		]
		"bottom" : [
			# {
			# 	"id" : "reset"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Reset All"
			# 	"icon" : "reset"
			# }
			{
				"id" : "shake"
				"type" : SongData.types.FX
				"disable" : true
				"label" : "Next Section"
				"icon" : "next"
			}
			# {
			# 	"id" : "end"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Finish"
			# 	"icon" : "end"
			# }
		]
	}

	"b1" : {
		"top" : [
			{
				"id" : "fx-delay"
				"type" : SongData.types.FX
				"label" : "FX : Delay"
				"icon" : "delay"
			}
			{
				"id" : "fx-filter"
				"type" : SongData.types.FX
				"label" : "FX : Filter"
				"icon" : "filter"
			}
			{
				"id" : "fx-repeater"
				"type" : SongData.types.FX
				"label" : "FX : Repeater"
				"icon" : "reverse"
			}
		]
		"bottom" : [
			{
				"id" : "to-section-C"
				"type" : SongData.types.CUE
				"label" : "Next Section"
				"icon" : "next"
			}
			# {
			# 	"id" : "to-section-A"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Go Back"
			# 	"icon" : "previous"
			# }
			# {
			# 	"id" : "end"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Finish"
			# 	"icon" : "end"
			# }
		]
	}

	"c1" : {
		"top" : [ 
			{
				"id" : "solo-synth"
				"type" : SongData.types.FX
				"label" : "Solo Synth"
				"icon" : "synth"
			}
		]
		"bottom" : [
			{
				"id" : "solo-synth-repeat"
				"type" : SongData.types.CUE
				"label" : "End Solo Synth"
				"icon" : "end"
			}
		]
	}

	"c2" : {
		"top" : [
			{
				"id" : "fx-delay"
				"type" : SongData.types.FX
				"label" : "FX : Delay"
				"icon" : "delay"
			}
			{
				"id" : "fx-filter"
				"type" : SongData.types.FX
				"label" : "FX : Filter"
				"icon" : "filter"
			}
			{
				"id" : "fx-repeater"
				"type" : SongData.types.FX
				"label" : "FX : Repeater"
				"icon" : "reverse"
			}
		]
		"bottom" : [
			{
				"id" : "to-section-D"
				"type" : SongData.types.CUE
				"label" : "Next Section"
				"icon" : "next"
			}
			# {
			# 	"id" : "reset"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Reset All"
			# 	"icon" : "reset"
			# }
			# {
			# 	"id" : "end"
			# 	"type" : SongData.types.CUE
			# 	"label" : "Finish"
			# 	"icon" : "end"
			# }
		]
	}


}



module.exports = SongData
