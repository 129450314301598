EventEmitter = require("events").EventEmitter


class GLCapabilities extends EventEmitter
	constructor:()->

	init:()->
		@canvas = document.createElement "canvas"
		try 
			@ctx = @canvas.getContext "webgl"
		catch e
			@emit "error", e

	getMaxTextureSize:()=>
		if @ctx
			try
				return @ctx.getParameter @ctx.MAX_TEXTURE_SIZE
			catch e
				@emit "error", e
		else
			@emit "error", "No WebGL context"
			

	close:()=>

		@ctx = null
		@canvas = null

module.exports = GLCapabilities