EventEmitter = require("events").EventEmitter

VIDEO_LIST = [
	"mmorph-logo"
	"sectionA"
	"sectionB"
	"sectionC"
	"sectionD"
	"sectionD2"
]

NUM_VIDEOS = VIDEO_LIST.length

class VideoManager extends EventEmitter
	constructor:(@staticMode=false)->

		if Main.getQueryParams()["noVideo"] then @staticMode = true


		@videos = {}
		@rq = new XMLHttpRequest()
		@rq.onload = @_videoLoaded
		@loaded = false

		@videoSuffix = ".webmhd.webm"

		if Main.getQueryParams()["sdVideo"] then @videoSuffix = ".webmsd.webm"

		@bgVideo = null
		@currentId = null
		@backupVideoList = VIDEO_LIST.slice();

	init:(@container)=>

	setLowQuality:()=>
		@staticMode = true
		VIDEO_LIST = @backupVideoList

		for key of @videos
			if (@videos[key].parentElement)
				@videos[key].parentElement.removeChild(@videos[key])

		@loadNextVideo()


	_videoLoaded:()=>

		if !@staticMode
			newVideo = document.createElement("video")
			newVideo.autoplay = false
			newVideo.loop = true
			newVideo.src = URL.createObjectURL(@rq.response)

		else
			newVideo = document.createElement("img")
			newVideo.className = "videoShim"
			newVideo.src = URL.createObjectURL(@rq.response)

		@videos[@currentVideo] = newVideo

		@container.appendChild newVideo

		videoLoadProgress = 1.0 - (VIDEO_LIST.length / NUM_VIDEOS)
		@emit "loadProgress", videoLoadProgress

		@loadNextVideo()

	loadNextVideo:()=>
		if VIDEO_LIST.length
			@currentVideo = VIDEO_LIST.pop()

			if !@staticMode
				url = "./assets/video/#{@currentVideo}#{@videoSuffix}"
			else
				url = "./assets/images/static/#{@currentVideo}.jpg"
			@rq.open("GET", url, true)
			@rq.responseType = "blob"
			@rq.send()
		else
			@loaded = true
			@emit "loaded", null

	showOverlay:()=>


		@container.querySelector(".overlay").style.opacity = 1

	showVideo:(id)=>

		if @currentId == id then return
		else @currentId = id

		# id += "-small"

		if @bgVideo
			@bgVideo.style.opacity = 0

		newBgVideo = @videos[id]
		
		if !newBgVideo
			console.error "video #{id} not found"
			return
		
		if !@staticMode
			newBgVideo.currentTime = 0
			newBgVideo.play()
			newBgVideo.style.opacity = 1
		else
			newBgVideo.style.opacity = 1

		setTimeout(()=>
			if @bgVideo and !@staticMode
				@bgVideo.pause()
			@bgVideo = newBgVideo
		, 500)







module.exports = VideoManager