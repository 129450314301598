# Circle-test app

IntroPage = require "../pages/IntroPage.coffee"
AboutPage = require "../pages/AboutPage.coffee"
OutroPage = require "../pages/OutroPage.coffee"
LogoPage = require "../pages/LogoPage.coffee"
CalibrationPage = require "../pages/CalibrationPage.coffee"

Ring = require "../ring/Ring.coffee"
FxController = require "../fx/FxController.coffee"
ShakeController = require "../fx/ShakeController.coffee"
SynthController = require "../fx/SynthController.coffee"

Headtracker = require "../control/Headtracker.coffee"
PhoneController = require "../control/PhoneController.coffee"

WaitingVis = require "../vis/WaitingVis.coffee"
WavesVis = require "../vis/WavesVis.coffee"
ShapesVis = require "../vis/ShapesVis.coffee"
GridVis = require "../vis/GridVis.coffee"

SongData = require "../score/SongData.coffee"
Director = require "../score/Director.coffee"

DebugView = require "../debug/DebugView.coffee"

AudioInterface = require "../heavy/AudioInterface.coffee"
OSCAudioInterface = require "../heavy/OSCAudioInterface.coffee"
HeavyAudioInterface = require "../heavy/HeavyAudioInterface.coffee"

VideoManager = require "../video/VideoManager.coffee"

GLCapabilities = require "../utils/GLCapabilities.coffee"

class App
	constructor:()->

		@snap = Snap("100%", "100%")

		# check power of this machine

		@hqMode = true
		# glCapabilities = new GLCapabilities()
		# glCapabilities.on "error", (e)=>
		# 	console.error "Error checking machine spec : ", e
		# glCapabilities.init()
		# maxTexSize = glCapabilities.getMaxTextureSize()
		# if maxTexSize > 8000
		# 	console.log "HQ mode enabled"
		# 	@hqMode = true
		# glCapabilities.close()

		if Main.getQueryParams()["lqMode"] then @hqMode = false
		if Main.getQueryParams()["hqMode"] then @hqMode = true

		# load everything else

		@audioInterface = new HeavyAudioInterface()
		@audioInterface.init()

		@videoManager = new VideoManager(!@hqMode)
		@videoManager.init($("background"))

		@svgContainer = $('svg-container')

		@waveVis = new WavesVis(@snap, @hqMode, @svgContainer)
		@waveVis.init()
		# $('svg-container').appendChild @waveVis.getNode()

		@shapesVis = new ShapesVis(@snap)
		@shapesVis.init()
		# $('svg-container').appendChild @shapesVis.getNode()

		@gridVis = new GridVis(@snap, @hqMode)
		@gridVis.init()
		# $('svg-container').appendChild @gridVis.getNode()
		
		@ring = new Ring(@snap)
		@ring.init()
		# $('svg-container').appendChild @ring.getNode()

		@fxControl = new FxController(@snap)
		@fxControl.init()
		# $('svg-container').appendChild @fxControl.getNode()

		@shakeControl = new ShakeController(@snap)
		@shakeControl.init()
		# $('svg-container').appendChild @shakeControl.getNode()

		@synthControl = new SynthController(@snap)
		@synthControl.init()
		# $('svg-container').appendChild @synthControl.getNode()

		@waitingVis = new WaitingVis(@snap)
		@waitingVis.init()
		# $('svg-container').appendChild @waitingVis.getNode()

		### About Page ###
		
		@aboutPage = new AboutPage()
		@aboutPage.init("about", "./assets/html/aboutPage.html", $("pages"))
		@aboutPage.on("loaded", ()=>
			$('menu-burger').addEventListener("click", ()=>
				document.body.classList.add("page-about")
				@aboutPage.show()
				)
			)
		@aboutPage.on("hide", ()=>
			document.body.classList.remove("page-about")
			)

		### Massive Logo ###

		$('mm-logo-white').addEventListener('click', ()=>
			window.open("http://massivemusic.com/", "_blank");
			)

		### Outro Page ###

		@outroPage = new OutroPage()
		@outroPage.init("outro", "./assets/html/outroPage.html", $("pages"))

		### Intro Page ###

		@introPage = new IntroPage()
		@introPage.init("intro", "./assets/html/introPage.html", $("pages"))

		### Phone Calibration Page ###
		@calibrationPage = new CalibrationPage()
		@calibrationPage.init("calibration", $("pages"))

		### Logo video page ###
		@logoPage = new LogoPage()
		@logoPage.init("logo", $("pages"))

		@introPage.on("quality", (highQuality)=>

			if !highQuality

				@videoManager.setLowQuality()
				@waveVis.setLowQuality()

			@audioInterface.initPatch(highQuality)

		)


		@introPage.on("hide", ()=>


			if !Main.useMouse

				document.body.classList.add("phone")

				@ring.connectController(@phoneControl)
				@fxControl.connectController(@phoneControl)
				@shakeControl.connectController(@phoneControl)
				@synthControl.connectController(@phoneControl)
				Director.connectController(@phoneControl)

				@calibrationPage.show()
				
				@phoneControl.on "signal", (signalData)=>
					switch signalData
						when "calibrate"
							@calibrationPage.showCenterMarker()
						when "center"
							@calibrationPage.showSideMarker()
						when "side"
							@calibrationPage.showTopMarker()
						when "top"
							@calibrationPage.calibrationFinished()
						when "start"
							@calibrationPage.hide()
							@start()

			else
				@setupMouseMarker()
				@start()


		)

		@introPage.on("phoneMode", (socket)=>

			@phoneControl = new PhoneController($('marker'))
			@phoneControl.init(socket)
			@phoneControl.start()
			@phoneControl.on("error", (data)=>
				#TODO ; deal with this
				console.error data
				)

			@phoneControl.on("codeChange", (newCode)=>
				@introPage.showGameId(@phoneControl.gameId)
			)


			@introPage.showGameId(@phoneControl.gameId)


			onPhoneControlStart = ()=>
				@introPage.hide()

			@phoneControl.on("start", onPhoneControlStart)

			window.addEventListener("resize", @phoneControl.resize)

			)

			
		

		# update loader & hide it when we're done

		### LOADING / STARTUP ###

		### 1. Load audio ###
		@audioInterface.on "loadProgress", (progress)=>
			window.__loader.setProgress(0.2 + (progress * 0.4))

		@audioInterface.once "loaded", ()=>

			@videoManager.loadNextVideo()

		### 2. Load video ###
		@videoManager.once "loadProgress", (progress)=>
			window.__loader.setProgress(0.6 + (progress * 0.4))


		### 3. Show logo ###
		@videoManager.once "loaded", ()=>

			window.__loader.hide()
			# @audioInterface.reset()
			@introPage.show @videoManager.videos["mmorph-logo"]
			# @logoPage.show @videoManager.videos["mmorph-logo"]

		### 4. Show intro page ###
		@logoPage.on "hide", ()=>
			@introPage.show()

		@setupDebugView()

	start:()=>

		@videoManager.showOverlay()

		# start experience
		Director.init(SongData, @ring, @fxControl, @shakeControl, @synthControl, @audioInterface, @waitingVis, @introPage, @outroPage, @waveVis, @shapesVis, @gridVis, @videoManager)
		Director.start()

	setupDebugView:()=>


		@debugView = DebugView
		@debugView.init()

		# Debug handlers
		@debugView.on("showRing", ()=>
			@ring.show(()=>)
		)

		@debugView.on("hideRing", ()=>
			@ring.hide(()=>)
		)

		@debugView.on("dimRing", ()=>
			@ring.dim(()=>)
		)

		@debugView.on("showFx", ()=>
			@fxControl.show("demo", ()=>)
		)

		@debugView.on("hideFx", ()=>
			@fxControl.hide(()=>)
		)

		@debugView.on("restartHeadTracker", ()=>
			@headtracker.stop()
			@headtracker.start()
			)

	setupMouseMarker:()=>

		@marker = document.body.querySelector("#userMarker")
		document.body.addEventListener("mousemove", (event)=>
			@marker.style.left = event.x + "px"
			@marker.style.top = event.y + "px"

			)


module.exports = App