Page = require "./Page.coffee"
Utils = require "../utils/Utils.coffee"
io = require "socket.io-client"

class IntroPage extends Page
	constructor:()->
		super


	onLoaded:(rq)=>
		super

		@highQuality = true

		@mouseButton = @element.querySelector("#mouse")
		@phoneButton = @element.querySelector("#phone")

		@codeDialog = @element.querySelector(".codeDialog")

		@mouseButton.addEventListener("click", @onMouseSelected)

		@titleElement = @element.querySelector(".title")
		@menu = @element.querySelector(".menu")
		@quality = @element.querySelector(".quality")
		@inputMethod = @element.querySelector(".inputMethod")

		# try out a socket connection to the server to see if it's alive
		@socket = io((document.location.hostname).split(':')[0] + ":8080")
		@socket.on("connect", ()=>
			@socket.on("pong", ()=> 
				console.log("tested socket server successfully")
				@phoneButton.classList.remove "disable"
				@phoneButton.addEventListener("click", @onPhoneSelected)
			)
			@socket.emit("ping")
		)
		@socket.on("connect_timeout", ()=>
			console.log("socket.io timeout, phone server not operational")
			
			)
		@socket.on("connect_error", ()=>
			console.log("socket.io error, phone server not operational")

			)

		@hdButton = @element.querySelector("#hd")
		@sdButton = @element.querySelector("#sd")

		@hd


		# if (typeof(io) == "undefined")
		# 	console.log "Socket.io not loaded, disabling phone mode"
		# 	@phoneButton.classList.add "disable"
		# else
		# 	@phoneButton.addEventListener("click", @onPhoneSelected)



	onMouseSelected:(event)=>

		Main.useMouse = true

		document.body.style.cursor = "none"

		@hide()

	onPhoneSelected:(event)=>

		Main.useMouse = false
		@emit("phoneMode", @socket)

	showGameId:(code)=>
		@codeDialog.querySelector("input").value = code
		@codeDialog.style.display = "block"

	show:(@video)=>
		super

		# allow skip
		if Main.getQueryParams()["skipIntro"]
			@onMouseSelected()
			return

		if @video
			@element.appendChild @video
			if @video.tagName.toLowerCase() == "video"
				@video.loop = false
				@video.addEventListener "ended", @onVideoEnded
			else
				setTimeout(()=>
					@onVideoEnded()
				,2000)
		else
			@onVideoEnded()

		setTimeout(()=>
			@element.classList.add("visible")
			if @video.play then @video.play()
			else @video.style.opacity = 1
		,500)

	onVideoEnded:()=>
		
		if @video
			@video.classList.add "top"
			if @video.tagName.toLowerCase() != "video"
				@video.style.opacity = 0


		setTimeout(()=>
			@menu.classList.add "visible"
			@quality.classList.add "visible"

			@hdButton.addEventListener("click", ()=>
				@emit("quality", @highQuality)
				@showInputSelect()
			)
			@sdButton.addEventListener("click", ()=>
				@highQuality = false
				@emit("quality", @highQuality)
				@showInputSelect()
			)

		, 1000)
	

	showInputSelect:()=>
		@quality.classList.remove("visible")
		setTimeout(()=>
			@inputMethod.classList.add "visible"
		, 500)

	hide:()=>
		
		@menu.classList.remove "visible"

		@element.classList.remove("visible")

		setTimeout(()=>
			super
			@emit("hide", @highQuality)
		,1000)
		



module.exports = IntroPage