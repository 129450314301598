ControllerBase = require "../base/ControllerBase.coffee"
Utils = require "../utils/Utils.coffee"
Noise = require "../lib/noise.js"

# everything relative to a 1000 x 1000 box inside viewport

NUM_SEGMENTS = 1
JOINTS = 15
WEBBING_ITERATIONS = 3
NUM_KEYFRAMES = 15
SEGMENT_RADIUS = 300
BOX_SIZE = Math.cos(Math.PI/4) * SEGMENT_RADIUS
INNER_BOX_MULT = 0.1
PATH_VARIANCE = 10
lineStack = []

SVG_XMLNS = "http://www.w3.org/2000/svg"

WAVES_ANGLES = []

ANIM_DURATION = "1200ms"

FREQUENCY = 10000

LOOP_INDEX = 5

class WavesVis extends ControllerBase
	constructor:(@snap, @hqMode=true)->
		super(@snap)

		if !@hqMode
			NUM_KEYFRAMES = 5

	getNode:()->
		return @s.node

	setLowQuality:()=>
		NUM_KEYFRAMES = 5

	init:(waveAngles)->

		@visible = false

		Noise.seed Math.random()

		@s = @snap.svg(0,0,"100%", "100%", 0,0,1000,1000)
		@s.attr({
			class : "wavesVis"
			})

		@removeFromDOM()

		@element = @s.g()
		@s.add @element

		# Linedata used to share lines between segments to everything lines up
		@lineData = {}

		@waves = {}


	createWave:(id, angle, width, index)=>

		if !@visible then return
		if @waves[id] then return

		waveGroup = @s.g()

		waveGroup.add @_createWaveGeometry(angle, width, index)
		waveGroup.add @_createWaveGeometry(angle+180, width, index+180)

		@waves[id] = waveGroup

		waveGroup.addClass "index-#{index}"

		@element.add waveGroup

		return waveGroup

	_createWaveGeometry:(angle, width, index)=>

		waveGroup = @s.g()

		topLineAngle = (angle-width/2)
		bottomLineAngle = (angle+width/2) + 0.5

		topLineId = index + 1
		bottomLineId = index

		if topLineId >= LOOP_INDEX and topLineId < 180 then topLineId = topLineId + 180 - LOOP_INDEX
		if topLineId >= (LOOP_INDEX + 180) then topLineId = topLineId - 180 - LOOP_INDEX
		if bottomLineId >= (LOOP_INDEX + 180) and bottomLineId < 180 then bottomLineId = bottomLineId - 180 - LOOP_INDEX
		

		# if not @lineData.hasOwnProperty(topLineId)
		# 	@lineData[topLineId] = @_createLine(topLineAngle)

		# if not @lineData.hasOwnProperty(bottomLineId)
		# 	@lineData[bottomLineId] = @_createLine(bottomLineAngle)

		console.log "topLineId : #{topLineId}"
		console.log "bottomLineId : #{bottomLineId}"

		topLineData = @_createLine(angle-width/2, topLineId)
		bottomLineData = @_createLine(angle+width/2, bottomLineId)

		# topLineData = @lineData[topLineId]
		# bottomLineData = @lineData[bottomLineId]

		middleLineData = @_createLine(angle, bottomLineId + 0.5)
		middleLineData.line.remove()

		waveGroup.add topLineData.line
		waveGroup.add bottomLineData.line

		waveGroup.add @_createWebbing(topLineData.points, bottomLineData.points, middleLineData.points)

		return waveGroup

	removeWaveById:(id)=>
		if @waves.hasOwnProperty(id)
			@waves[id].remove()
			delete @waves[id]
		else
			console.error "Could not find wave with id : #{id}"

	removeAllWaves:()=>
		for waveId of @waves
			@removeWaveById(waveId)


	_createLine:(angle, lineId)=>

		if not @lineData.hasOwnProperty(lineId)
			pointCollection = []
			seed = Math.random()
			for i in [0..NUM_KEYFRAMES-1] by 1
				
				noiseIndex = i / NUM_KEYFRAMES
				if (i < NUM_KEYFRAMES/2)
					noiseIndex = 1 - (i / NUM_KEYFRAMES)

				pointCollection.push @_createPoints(angle, 15.0, noiseIndex, seed, i)

			@lineData[lineId] = pointCollection

		pointCollection = @lineData[lineId]

		valuesString = ""
		for i in [0..pointCollection.length-1] by 1
			valuesString += pointCollection[i].join(",") + ";"

		path = @s.polyline([500,500]).attr({ class : "wobbleLine" })

		animateEl = document.createElementNS(SVG_XMLNS, "animate")
		animateEl.setAttribute "dur", ANIM_DURATION
		animateEl.setAttribute "repeatCount", "indefinite"
		animateEl.setAttribute "attributeName", "points"
		animateEl.setAttribute "animationDirection", "alternate"
		animateEl.setAttribute "values", valuesString

		path.node.appendChild animateEl

		return {
			line : path
			points : pointCollection
		}


	_createPoints:(angle, scale, noiseIndex, seed, keyframeIndex)=>

		# guideA1 = Utils.cartPolar angle, SEGMENT_RADIUS * scale
		# guideA2 = Utils.cartPolar angle, SEGMENT_RADIUS * scale

		# startA = Utils.getLineSquareIntersection 500, 500, scale * BOX_SIZE * INNER_BOX_MULT * 2, 500, 500, 500 + guideA1.x, 500 + guideA1.y
		# endA = Utils.getLineSquareIntersection 500, 500, scale * BOX_SIZE * 2, 500, 500, 500 + guideA2.x, 500 + guideA2.y

		startA = Utils.cartPolar angle, SEGMENT_RADIUS
		endA = Utils.cartPolar angle, SEGMENT_RADIUS * 5.0

		points = []

		points.push Math.floor 500 + startA.x
		points.push Math.floor 500 + startA.y

		for i in [0..JOINTS] by 1

			jointDistance = Noise.simplex2(Math.random(), i / JOINTS) * PATH_VARIANCE * scale * (i / JOINTS)

			jointPoint = Utils.getPerpendicularLineAtDistance 500 + startA.x, 500 + startA.y, 500 + endA.x, 500 + endA.y, i/JOINTS, jointDistance

			points.push Math.floor(jointPoint.x), Math.floor(jointPoint.y)

		points.push Math.floor(500 + endA.x), Math.floor(500 + endA.y)

		return points



	_createWebbing:(topPointGroup, bottomPointGroup, middlePointGroup)=>

		webbingGroup = @s.g().attr({ class : "webbingLine" })

		groups = [
			topPointGroup,
			middlePointGroup,
			bottomPointGroup
		]

		# TODO : randomise group order per keyframe

		numPoints = groups[0][0].length

		NUM_PASSES = 1

		for p in [0..NUM_PASSES-1] by 1
			keyframes = []

			complexStart = Math.floor(Math.random() * numPoints / 4) * 2

			for j in [0..NUM_KEYFRAMES-1] by 1
				framePoints = []

				i = 4
				while i < (numPoints-2)

					if (i == complexStart) and (i < (numPoints - 6))
						framePoints.push(groups[0][j][i])
						framePoints.push(groups[0][j][i+1])

						framePoints.push(groups[1][j][i+2])
						framePoints.push(groups[1][j][i+3])

						framePoints.push(groups[2][j][i])
						framePoints.push(groups[2][j][i+1])

						framePoints.push(groups[2][j][i+2])
						framePoints.push(groups[2][j][i+3])

						framePoints.push(groups[2][j][i+4])
						framePoints.push(groups[2][j][i+5])

						framePoints.push(groups[1][j][i+2])
						framePoints.push(groups[1][j][i+3])

						framePoints.push(groups[0][j][i+4])
						framePoints.push(groups[0][j][i+5])

						i += 4
					else

						group = groups[0]
						if (i % 4 == 0) then group = groups[2]

						framePoints.push(group[j][i])
						framePoints.push(group[j][i + 1])

						i += 2

				keyframes.push framePoints

			triangle = @_createTriangle(keyframes)
			webbingGroup.add triangle


		return webbingGroup

	_shuffleArray:(arr)=>
		i = arr.length;
		if i == 0 then return false

		while --i
			j = Math.floor(Math.random() * (i+1))
			tempi = arr[i]
			tempj = arr[j]
			arr[i] = tempj
			arr[j] = tempi
		return arr

	_createTriangle:(pointKeyframes)=>
		triangleString = ""
		for i in [0..NUM_KEYFRAMES-1] by 1
			points = pointKeyframes[i]
			triangleString += points.join(',') + ";"

		newPath = @s.polyline([500,500]).attr({ class : "webbingTri" })

		animateEl = document.createElementNS(SVG_XMLNS, "animate")
		animateEl.setAttribute "dur", ANIM_DURATION
		animateEl.setAttribute "repeatCount", "indefinite"
		animateEl.setAttribute "attributeName", "points"
		animateEl.setAttribute "animationDirection", "alternate"
		animateEl.setAttribute "values", triangleString

		newPath.node.appendChild animateEl

		return newPath

	_pickRandom:(list)=>
		return list[Math.floor(Math.random() * list.length)]

	show:(callback)=>

		@addToDOM()

		@visible = true
		console.log "Showing WavesVis"

		@s.addClass("visible")


	hide:(callback)=>

		@visible = false
		console.log "Hiding WavesVis"

		@s.removeClass("visible")
		# @removeAllWaves()

		setTimeout(()=>
			@removeFromDOM()
		, 500)






module.exports = WavesVis