ControllerBase = require "../base/ControllerBase.coffee"
Utils = require "../utils/Utils.coffee"
Noise = require "../lib/noise.js"

# everything relative to a 1000 x 1000 box inside viewport


SVG_XMLNS = "http://www.w3.org/2000/svg"

ANIM_DURATION = "1200ms"

UPDATE_INTERVAL = 2.04 * 1000 * 0.25
MAX_LIFE = 4

class ShapesVis extends ControllerBase
	constructor:(@snap)->

	getNode:()->
		return @s.node

	init:()->

		@visible = false

		Noise.seed Math.random()

		@s = @snap.svg(0,0,"100%", "100%", 0,0,1000,1000)
		@s.attr({
			class : "shapesVis"
			})

		@removeFromDOM()

		@element = @s.g()
		@s.add @element

		@updateIntervalId = -1

		@shapes = []

	show:(callback)=>

		@addToDOM()

		@visible = true
		console.log "Showing ShapesVis"

		@s.addClass("visible")

		clearTimeout(@updateIntervalId)
		@update()

	update:()=>

		

		clearTimeout(@updateIntervalId)
		@updateIntervalId = setTimeout(@update, UPDATE_INTERVAL)

		shapeIndex = Math.floor(Math.random() * 5)

		cX = 500
		cY = 500

		dest = Utils.cartPolar Math.floor(Math.random() * 360), 2000

		# shapeIndex = 0
		switch shapeIndex
			when 0

				# new circle!

				circleOuter = @s.g()
				newCircle = @s.circle(cX, cY, 30).attr( { class : "visCircle" })
				circleOuter.add newCircle

				animIndex = Math.floor(Math.random() * 3)+1
				newCircle.addClass "anim-#{animIndex}"

				bbox = circleOuter.getBBox()
				circleOuter.animate({
					transform : "T#{500+dest.x},#{500+dest.y}"
					# transform : "s5,5,#{bbox.cx},#{bbox.cy}"
					}, 4000)

				# newCircle.node.style.transform = "translate(#{500+dest.x}px,#{500+dest.y}px)"

				@element.add circleOuter
				@shapes.push new ShapeClass(circleOuter)

			when 1

				# new mini circle group!

				# cX = Math.floor(Math.random() * 2000)-500
				# cY = Math.floor(Math.random() * 1000)
				circleGroupOuter = @s.g()
				circleGroup = @s.g()
				circleGroupOuter.add circleGroup

				for i in [0..5] by 1
					smallCircle = @s.circle(cX, cY, 10).attr({ class : "visSmallCircle" })
					animIndex = Math.floor(Math.random() * 3)+1
					smallCircle.addClass "anim-#{animIndex}"
					circleGroup.add smallCircle

				bbox = circleGroupOuter.getBBox()
				circleGroupOuter.animate({
					transform : "T#{500+dest.x},#{500+dest.y}"
					# transform : "s5,5,#{bbox.cx},#{bbox.cy}"
					}, 4000)

				@element.add circleGroupOuter
				@shapes.push new ShapeClass(circleGroupOuter)

			when 2

				# new mini follower circle group!

				# cX = Math.floor(Math.random() * 1500)-500
				# cY = Math.floor(Math.random() * 1000)

				circleGroupOuter = @s.g()
				circleGroup = @s.g()
				circleGroupOuter.add circleGroup

				animIndex = Math.floor(Math.random() * 3)+1

				for i in [0..5] by 1
					smallCircle = @s.circle(cX, cY, 10).attr({ class : "visSmallCircleFollow" })
					
					smallCircle.addClass "anim-#{animIndex}"
					circleGroup.add smallCircle

				bbox = circleGroupOuter.getBBox()
				circleGroupOuter.animate({
					transform : "T#{500+dest.x},#{500+dest.y}"
					# transform : "s5,5,#{bbox.cx},#{bbox.cy}"
					}, 4000)

				@element.add circleGroupOuter
				@shapes.push new ShapeClass(circleGroupOuter)

			when 3,4

				# arc!

				radius = Math.floor(Math.random() * 500) + 200

				startAngle = Math.floor(Math.random() * 360)
				width = 40 + Math.floor(Math.random() * 50)

				start = Utils.cartPolar startAngle, radius
				end = Utils.cartPolar startAngle+width, radius

				pathString = ""
				pathString += "M #{500 + start.x} #{500 + start.y} "
				pathString += "A #{radius},#{radius} 0 0,1 #{500 + end.x}, #{500 + end.y}"
				# pathString += "L " + (500 + end.x) + " " + (500 + end.y) + " "
			
				arcGroup = @s.g().attr({ class : "visArcGroup" })
				arcGroup.add @s.path(pathString).attr({ class : "visArc" })
				arcGroup.add @s.circle(500,500,radius).attr({ class : "visArcPlacementCircle" })

				@element.add arcGroup
				@shapes.push new ShapeClass(arcGroup)

		for shape in @shapes

			if shape
				shape.update()
				# check lifetime and remove old ones
				if shape.lifetime > MAX_LIFE
					shape.die()
					@shapes.splice @shapes.indexOf(shape), 1

	hide:(callback)=>

		@visible = false
		console.log "Hiding ShapesVis"

		@s.removeClass("visible")

		clearTimeout(@updateIntervalId)

		for shape in @shapes
			if shape
				shape.die()
		@shapes = []

		setTimeout(()=>
			@removeFromDOM()
		, 1000)


class ShapeClass
	constructor:(@el)->
		@lifetime = 0

	update:()=>
		@lifetime++

	die:()=>
		@el.remove()



module.exports = ShapesVis