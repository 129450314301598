ControllerBase = require "../base/ControllerBase.coffee"

# Requires Snap

RingGroup = require "./RingGroup.coffee"
RingSegment = require "./RingSegment.coffee"

# everything relative to a 1000 x 1000 box inside viewport

OUTER_RADIUS = 300
INNER_RADIUS = 180
MARKER_RADIUS = 23

class Ring extends ControllerBase
	constructor:(@snap)->

		@visible = false

	getNode:()->
		return @s.node

	init:()->

		@s = @snap.svg(0,0,"100%", "100%", 0,0,1000,1000);
		@s.attr({
			class : "ring"
			})

		@element = @s.g()

		@removeFromDOM()

		@topGroup = new RingGroup(@element, {}, true, 0, OUTER_RADIUS, INNER_RADIUS, MARKER_RADIUS)
		@bottomGroup = new RingGroup(@element, {}, false, 0, OUTER_RADIUS, INNER_RADIUS, MARKER_RADIUS)

		@segmentLabelTimeoutId = -1
		@segmentLabelGroup = @s.g().attr({ class : "ringLabelGroup" })
		@segmentLabel = @s.text(500,500, "LABEL").attr({
			class : "ringLabel"
			})
		@segmentLabelGroup.add @segmentLabel


	setTopGroup:(data, allSelectedThreshold)=>

		# TODO : transition out of old ring

		@topGroup.hide(()=>

			@topGroup.destroy()
			@topGroup = new RingGroup(@element, data, true, data.length, OUTER_RADIUS, INNER_RADIUS, MARKER_RADIUS, allSelectedThreshold) 

			@topGroup.on("selected", (data)=>

				@emit "selected", data
				@showLabel(data.label, true)
				)

			@topGroup.on("allSelected", (data)=>
				@emit("allSelected", "top")
				)

			@topGroup.on("hover", (data)=>
				@showLabel(data.label)
				)

			@topGroup.on("deselect", (data)=>
				@hideLabel()
				)
		)

	setBottomGroup:(data, allSelectedThreshold)=>

		@bottomGroup.hide(()=>

			@bottomGroup.destroy()
			@bottomGroup = new RingGroup(@element, data, false, data.length, OUTER_RADIUS, INNER_RADIUS, MARKER_RADIUS, allSelectedThreshold)

			@bottomGroup.on("selected", (data)=>

				@emit "selected", data
				@showLabel(data.label, true)

				)
			@bottomGroup.on("allSelected", (data)=>
				@emit("allSelected", "bottom")
				)

			@bottomGroup.on("hover", (data)=>
				@showLabel(data.label)
				)

			@bottomGroup.on("deselect", (data)=>
				@hideLabel()
				)
		)

	show:(callback)=>

		@addToDOM()

		console.log "Showing ring"
		@visible = true

		if Main.useMouse
			document.body.classList.add("marker")

		@topGroup.show(()=>)
		@bottomGroup.show(()=>)

		@topGroup.enable()

		@s.addClass("visible")
		@s.removeClass("dim")

		# bbox = @element.getBBox()
		# @element.animate( {
		# 	transform : "s1,1," + bbox.cx + "," + bbox.cy
		# 	}, 200, mina.easeIn, callback)
		callback()

	hide:(callback)=>

		console.log "Hiding ring"
		@visible = false

		# if Main.useMouse
		# 	document.body.classList.remove("marker")

		@s.removeClass("dim")

		@topGroup.hide(()=>)
		@bottomGroup.hide(()=>)

		# bbox = @element.getBBox()
		# @element.animate( {
		# 	transform : "s0,0," + bbox.cx + "," + bbox.cy
		# 	}, 200, mina.easeIn, ()=>

		# 		@s.removeClass("visible")
		# 		callback()
		# 	)

		setTimeout(()=>
			@s.removeClass("visible")
			@removeFromDOM()
			callback()
		,200)

		@hideLabel()

	dim:(callback)=>

		console.log "Dimming ring"

		@s.addClass("dim")
		@topGroup.enabled = false
		@bottomGroup.enabled = false

		# bbox = @element.getBBox()
		# @element.animate( {
		# 	transform : "s0.2,0.2," + bbox.cx + "," + bbox.cy
		# 	}, 200, mina.easeIn, callback)

		callback()

	showLabel:(text, select=false)=>
		@segmentLabel.node.innerHTML = text
		@segmentLabel.addClass("visible")

		if select
			@segmentLabel.addClass("selected")
			@segmentLabelGroup.addClass("selected")
		else
			@segmentLabel.removeClass("selected")
			@segmentLabelGroup.removeClass("selected")

	hideLabel:()=>
		clearTimeout(@segmentLabelTimeoutId)
		@segmentLabel.removeClass("visible")




	message:(address, value)=>
		@topGroup.message address, value
		@bottomGroup.message address, value


	connectController:(@controller)=>
		@controller.on("active", (isActive)=>
			if !isActive and @visible
				@topGroup.deselect()
				@bottomGroup.deselect()
		)
		@controller.on("angle", (angle)=>
			if @visible
				@topGroup.selectByAngle(angle)
				@bottomGroup.selectByAngle(angle)
				# console.log "selecting by angle #{angle}"
		)

		@controller.on("click", (data)=>
			if @visible
				@topGroup.selectByAngle(data.angle, true)
				@bottomGroup.selectByAngle(data.angle, true)
			)





module.exports = Ring