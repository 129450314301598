ControllerBase = require "../base/ControllerBase.coffee"
Utils = require "../utils/Utils.coffee"
RingSegment = require "../ring/RingSegment.coffee"


# everything relative to a 1000 x 1000 box inside viewport

OUTER_RADIUS = 300
INNER_RADIUS = 180
MAX_DASH = 200

OUTER_SCALE = 1.2
ALT_SCALE = 1.4
INDICATOR_SCALE = 1.1
INDICATOR_REF_X = 0

class FxController extends ControllerBase
	constructor:(@snap)->

		@intensity = 0
		@visible = false

		@bar_timeout = 4 * 4 * 0.5 * 1000

	getNode:()->
		return @s.node

	init:()->

		@autoExitTimeout = -1

		@rand = Math.random() * 0.5
		@showingExit = false

		@s = @snap.svg(0,0,"100%", "100%", 0,0,1000,1000);
		@s.attr({
			class : "fxController"
			})

		@removeFromDOM()

		@g = @s.g()
		@s.add @g

		@circles = @s.g()
		@g.add @circles

		@outerCircle = @s.circle(500,500,OUTER_RADIUS * OUTER_SCALE)
		@outerCircle.attr({			
			class : "outerCircle"
		})

		# @innerCircle = @s.circle(500,500,INNER_RADIUS * 0.5)
		# @innerCircle.attr({			
		# 	class : "innerCircle"
		# })

		@altCircle = @s.circle(500,500,OUTER_RADIUS * ALT_SCALE)
		@altCircle.attr({			
			class : "altCircle"
			})

		@circles.add @outerCircle
		# @circles.add @innerCircle
		@circles.add @altCircle

		# value indicator
		INDICATOR_REF_X = 500 - (OUTER_RADIUS * INDICATOR_SCALE * 1.2)
		@maskLeft = @s.rect(500,0,1000,1000).attr({ fill : "#fff" })
		@maskRight = @s.rect(0,0,500,1000).attr({ fill : "#fff" })

		@valueMask = @s.g()
		@valueMask.add @maskLeft
		@valueMask.add @maskRight

		@valueIndicator = @s.circle(500,500,0).attr({ fill : "#fff", class : "valueIndicator", mask : @valueMask })

		# @valueMask = @s.circle(500,500, OUTER_RADIUS * INDICATOR_SCALE).attr({ fill : "#fff"})
		# @valueIndicator = @s.rect(500,0,0,1000).attr({ 
		# 	fill : "#fff" 
		# 	mask : @valueMask
		# 	class : "valueIndicator"
		# 	})


		# exit Mask

		# @exitMask = @s.g()

		
		# @exitMask.add @s.circle(500,500, OUTER_RADIUS * 1.15).attr({fill : "#fff" })

		# @exitMask.add @s.circle(500,500,INNER_RADIUS * 0.8).attr({ fill : "#000"})

		posStart = Utils.cartPolar(90 + 15, OUTER_RADIUS * 2)
		posEnd = Utils.cartPolar(90 - 15, OUTER_RADIUS * 2)

		pathString = "M 500 500"
		pathString += "L " + (500 + posStart.x) + " " + (500 + posStart.y) + " "
		pathString += "L 0 1000 L 0 0 L 1000 0 L 1000 1000"
		pathString += "L " + (500 + posEnd.x) + " " + (500 + posEnd.y) + " "
		pathString += "L 500 500 "
		pathString += "Z"

		# @exitMask.add @s.path(pathString).attr({ fill : "#fff" })
		

		# @circles.attr({
		# 	mask : @exitMask
		# 	})

		# add exit

		@exitGroup = @s.g().attr({ class : "exitGroup"})

		exitPosition = Utils.cartPolar(90, OUTER_RADIUS * (OUTER_SCALE + (ALT_SCALE - OUTER_SCALE) /2 ))

		posStart = Utils.cartPolar(90 + 12, OUTER_RADIUS * 2)
		posEnd = Utils.cartPolar(90 - 12, OUTER_RADIUS * 2)

		pathString = "M 500 500"
		pathString += "L " + (500 + posStart.x) + " " + (500 + posStart.y) + " "
		pathString += "L " + (500 + posEnd.x) + " " + (500 + posEnd.y) + " "
		pathString += "L 500 500"
		pathString += "Z"

		@exitHighlight = @s.path(pathString).attr({ class : "segmentHighlight"})

		highlightMask = @s.g()
		outerCircle = @s.circle(500,500, (OUTER_RADIUS * ALT_SCALE * 1.05)).attr({ fill : "#fff"})
		innerCircle = @s.circle(500,500,(OUTER_RADIUS * OUTER_SCALE * 0.95)).attr({fill : "#000"})
		highlightMask.add outerCircle
		highlightMask.add innerCircle

		clipRectSize = 200
		highlightClip = @s.rect(500 + exitPosition.x - clipRectSize/2, 500 + exitPosition.y - clipRectSize/2, clipRectSize, clipRectSize)

		@exitHighlight.attr({
			stroke : "none"
			mask : highlightMask
			clip : highlightClip
			})

		@exitIconElement = @s.g().attr({ class : "iconContainer" })
		exitIconOuter = @s.circle(500 + exitPosition.x, 500 + exitPosition.y, 30).attr({
			class : "iconOuter"
			fill : "none"
			stroke : "rgba(255,255,255,0.8)"
		})
		@exitIconElement.add exitIconOuter

		# use Ring Segment to hande exit

		@exitSegment = new RingSegment(@s, @exitHighlight, @exitIconElement, { id : "back"}, 500 + exitPosition.x, 500 + exitPosition.y, 90+12, 90-12)
		@exitSegment.on("selected", ()=>
			@emit("exit", @id)
		)

		@exitGroup.add @exitHighlight
		@exitGroup.add @exitIconElement

		@exitGroup.remove()

		# progress timer
		timerRadius = OUTER_RADIUS * ALT_SCALE * 1.15
		@timerGroup = @s.g().attr({ class : "timerGroup" })
		for i in [0..1] by 1
			pathString = "M 500 #{500 + timerRadius}"
			pathString += "A #{timerRadius},#{timerRadius} 0 0,#{i} 500,#{500 - timerRadius}"
			path = @s.path(pathString).attr({ class : "timerPath"})
			@timerGroup.add path

		@g.add @timerGroup

		@smallTimer = @s.circle(500,640, 10).attr({ class : "smallTimer" })
		@g.add @smallTimer


		@update();

	update:()=>

		dashSize = Math.floor(MAX_DASH * (1 - @intensity))

		dashSize = Math.max(2, dashSize)

		rand = @rand * dashSize * 0.5

		@outerCircle.attr({
			"stroke-dasharray" : "#{dashSize * 1.2}, #{rand}, #{dashSize * OUTER_SCALE}"
			})

		@altCircle.attr({
			"stroke-dasharray" : "#{dashSize * 1.22}, #{rand}, #{dashSize * ALT_SCALE}"
			})

		@altCircle.attr({
			"stroke-dashoffset" : "#{dashSize * 1.22 + rand}"
			})
		@outerCircle.attr({
			"stroke-dashoffset" : "#{dashSize * -1.2 + rand}"
			})

		if @intensity > 0.5
			# @valueIndicator.attr({ x : 500, width : parseInt(( OUTER_RADIUS * INDICATOR_SCALE * 1.2 * ((@intensity - 0.5) * 2.0)))})
			@maskLeft.attr({ fill : "#fff" })
			@maskRight.attr({ fill : "#000" })
			@valueIndicator.attr({ r : parseInt(( OUTER_RADIUS * INDICATOR_SCALE * ((@intensity - 0.5) * 2.0))) })
		else
			@maskLeft.attr({ fill : "#000" })
			@maskRight.attr({ fill : "#fff" })
			@valueIndicator.attr({ r : parseInt(( OUTER_RADIUS * INDICATOR_SCALE * ((0.5 - @intensity) * 2.0))) })

	show:(@id, callback, autoExit=true)=>

		@addToDOM()

		document.body.classList.add(@id)
		@visible = true

		@rand = Math.random() * 0.5
		@intensity = 0

		console.log "Showing fx"

		@s.addClass("visible")

		

		twistTextLabel = ""
		if Main.useMouse

			if @indicatorGroup
				@indicatorGroup.remove()
				@indicatorGroup = null

			@indicatorGroup = @s.g().attr( { class : "indicator" })
			@g.add @indicatorGroup

			@indicatorGroup.add @s.image("assets/images/icons/icon-left-arrow.svg", 360, 470, 60,60).attr({ class : "arrow" })
			@indicatorGroup.add @s.image("assets/images/icons/icon-right-arrow.svg", 575, 470, 60,60).attr({ class : "arrow" })

			twistTextLabel = "MOVE"
		else
			twistTextLabel = "TWIST!"

		if @twistText
			@twistText.remove()
			@twistText = null

		@twistText = @s.text(500,500,twistTextLabel).attr({ class : "fxLabel" })
		@g.add @twistText
		@twistText.addClass("visible")
		@twistText.addClass("attention")

		if Main.useMouse
			@s.node.addEventListener("mousemove", @onMouseMove.bind(this))

		# bbox = @g.getBBox()
		# @g.animate( {
		# 	transform : "s1,1," + bbox.cx + "," + bbox.cy
		# 	}, 200, mina.easeIn, callback)

		@updateTimeoutId = setInterval(@update, 100)

		

		if autoExit
			@timerGroup.addClass("animate")
			@smallTimer.addClass("animate")
			clearTimeout(@autoExitTimeout)
			@autoExitTimeout = setTimeout(()=>
				@emit("exit", @id)
			,@bar_timeout)

	showExit:()=>
		if @showingExit then return
		else @showingExit = true

		# @exitGroup.addClass("visible")
		# @exitSegment.enable()

	hide:(callback)=>

		console.log "Hiding fx"
		@intensity = 0
		@visible = false

		clearTimeout(@autoExitTimeout)

		if @indicatorGroup
			@indicatorGroup.remove()
			@indicatorGroup = null

		if @twistText
			@twistText.remove()
			@twistText = null

		@exitSegment.deselect()
		@exitSegment.disable()
		@exitGroup.removeClass("visible")
		@showingExit = false

		@timerGroup.removeClass("animate")
		@smallTimer.removeClass("animate")

		if Main.useMouse
			@s.node.removeEventListener("mousemove", @onMouseMove.bind(this))


		document.body.classList.remove(@id)

		@s.removeClass("visible")

		setTimeout(()=>
			if !@visible
				@removeFromDOM()
				if callback then callback()
		,500)

		clearTimeout(@updateTimeoutId)
	
	connectController:(@controller)=>

		@exitSegment.enabled = false

		@controller.on("active", (isActive)=>
			if !isActive and @visible
				@exitSegment.deselect()
		)
		@controller.on("angle", (angle)=>
			if @visible
				@exitSegment.selectByAngle(angle)
				# console.log "selecting by angle #{angle}"
		)
		@controller.on("x", @onControllerMove)
		@controller.on("gamma", @onControllerTwist)
		@controller.on("click", @onControllerClick)

	onControllerMove:(value)=>

		if not @visible then return

		# @intensity = value + 0.5

		# if @intensity > 0.5
		# 	@showExit()

		# @emit("intensityChange", { id : @id, intensity : @intensity })


	onControllerClick:()=>
		# handle this

	onControllerTwist:(value)=>

		if not @visible then return

		# console.log value
		value = Math.min(80, value)
		value = Math.max(-80, value)

		@intensity = ((value / 80.0)/2.0) + 0.5
		# @intensity *= 2.0
		@intensity = Math.min(1.0, @intensity)
		@intensity = Math.max(0.0, @intensity)

		console.log "intensity : #{@intensity}"

		if @intensity > 0.5
			@showExit()

		@emit("intensityChange", { id : @id, intensity : @intensity })

	onMouseMove:(event)=>

		rect = @s.node.getBoundingClientRect()

		localX = event.layerX - rect.left
		# localY = event.layerY - rect.top

		localX = localX / rect.width
		# localY = localY / rect.height

		# disable Y
		localY = 0

		length = Math.sqrt(Math.pow(localX, 2) + Math.pow(localY, 2))

		length = Math.min(1,length)
		@intensity = length

		# console.log @intensity

		if @intensity > 0.5
			@showExit()

		@emit("intensityChange", { id : @id, intensity : @intensity })

module.exports = FxController