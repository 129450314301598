ControllerBase = require "../base/ControllerBase.coffee"
Utils = require "../utils/Utils.coffee"

# everything relative to a 1000 x 1000 box inside viewport

NUM_COLUMNS = 20
NUM_ROWS = 20
CELL_HEIGHT = 50
CELL_WIDTH = 200
SHAPE_SIZE = 30

class GridVis extends ControllerBase
	constructor:(@snap, @hqMode)->

		@hasGrid = false
		@hideTimeoutId = -1

	getNode:()->
		return @element

	addToDOM:()=>
		try 
			$("svg-container").appendChild(@element)
		catch error
			console.error error
		

	removeFromDOM:()=>
		try
			if @element.parentElement
				@element.parentElement.removeChild(@element)
		catch error
			console.error error

	init:(@container)->

		@visible = false
		@locked = true

		@element = el "div", "gridVis"

		@removeFromDOM()

		window.addEventListener "resize", @resize

		@shapeLookup = [
			(x,y)=>
				return @s.rect(x-SHAPE_SIZE/2, y-SHAPE_SIZE/2, SHAPE_SIZE, SHAPE_SIZE)
			(x,y)=>
				return @createNgon(x,y, 6, SHAPE_SIZE)
			(x,y)=>
				return @s.circle(x,y, SHAPE_SIZE/2)
			(x,y)=>
				return @createNgon(x,y,3, SHAPE_SIZE, -90)
			(x,y)=>
				return @createNgon(x,y,8, SHAPE_SIZE, 22.5)
		]


		@createGrid()


	createGrid:()=>

		if @hasGrid then return
		@hasGrid = true

		# remove old grid
		if @gridContainer
			@gridContainer.parentElement.removeChild(@gridContainer)
			@gridContainer = null

		@gridContainer = el "div", "gridContainer"
		@element.appendChild @gridContainer

		NUM_COLUMNS = Math.floor(window.innerWidth / CELL_WIDTH)
		NUM_ROWS = Math.floor(window.innerHeight / CELL_HEIGHT)

		@gridContainer.appendChild @createSubGrid()
		@gridContainer.appendChild @createSubGrid()

		# set initial conditions
		@gridContainer.classList.add "smallMode"


	createSubGrid:()=>
		newSubGrid = el "div", "subgrid"

		for i in [0..NUM_ROWS] by 1
			newSubGrid.appendChild @createDOMGrid()

		return newSubGrid


	createDOMGrid:()=>
		newRow = el "div", "row"
		newRow.style.height = CELL_HEIGHT + "px"

		lastType = "none"

		for i in [0..NUM_COLUMNS] by 1
			newCell = el "div", "cell"
			newCell.style.width = CELL_WIDTH + "px"
			newCell.style.height = CELL_HEIGHT + "px"
			type = "fill"
			idx = Math.random()
			if idx < 0.3 then type = "fill"
			else if idx < 0.5 then type = "stroke"
			else type = "none"


			if type != "none"
				newCell.classList.add "seed-" + Math.floor(Math.random() * 39)


			newRow.appendChild newCell

		return newRow




	# createSubGrid:(yStartOffset)=>

	# 	subGrid = @s.g().attr({class : "subgrid"})

	# 	gridWidth = 1000 / NUM_COLUMNS

	# 	xOffset = gridWidth/2
	# 	for i in [0..NUM_COLUMNS-1] by 1
	# 		newColumn = @s.g()
	# 		yOffset = yStartOffset
	# 		lastType = "none"
	# 		for j in [0..NUM_ROWS-1] by 1
	# 			type = "fill"
	# 			idx = Math.random()
	# 			if idx < 0.3 then type = "fill"
	# 			else if idx < 0.6 then type = "stroke"
	# 			else type = "none"

	# 			if type == lastType
	# 				if Math.random() > 0.2 then type = "none"

	# 			if type != "none"
	# 				newShape = @shapeLookup[i % 5](xOffset+SHAPE_SIZE/2, yOffset+SHAPE_SIZE/2)
	# 				newShape.addClass "gridShape"
	# 				newShape.addClass type
	# 				newShape.addClass "row-#{(i%5)}"
	# 				newShape.addClass "seed-" + Math.floor(Math.random() * 10)
	# 				newColumn.add newShape

	# 			lastType = type

	# 			yOffset += CELL_HEIGHT

	# 		subGrid.add newColumn
	# 		xOffset += gridWidth
		
	# 	return subGrid


	# getNgonPoints:(numPoints, radius, angleOffset=0)=>
	# 	points = []
	# 	for i in [0..numPoints-1] by 1
	# 		points.push Utils.cartPolar ((i/numPoints) * 360) + angleOffset, radius
	# 	return points

	# createNgon:(x,y, numPoints, radius, angleOffset=0)=>
	# 	points = @getNgonPoints(numPoints, radius/2, angleOffset)
	# 	pathString = "M #{x + points[0].x} #{y + points[0].y}"
	# 	for i in [1..points.length-1] by 1
	# 		pathString += "L #{x + points[i].x} #{y + points[i].y}"
	# 	pathString += "Z"
	# 	return @s.path(pathString)

	show:(callback)=>

		clearTimeout(@hideTimeoutId)

		@addToDOM()

		@visible = true

		@gridContainer.style.transitionDuration = "0.5s"
		@gridContainer.style.opacity = 1

		console.log "Showing GridVis"

		# @s.addClass("visible")
		@element.classList.add "visible"


	hide:(time=4)=>

		@visible = false

		console.log "Hiding GridVis"

		@gridContainer.style.transitionDuration = "#{time}s"
		@gridContainer.style.opacity = 0
		clearTimeout(@hideTimeoutId)
		@hideTimeoutId = setTimeout(()=>
			# @s.removeClass("visible")
			@element.classList.remove "visible"
			# @gridContainer.remove()
			@removeFromDOM()
		, time * 1000)

	setOpacity:(value)=>
		if @gridContainer
			@gridContainer.style.opacity = value

	setColorMode:()=>
		if @gridContainer
			@gridContainer.classList.remove "smallMode"

	setScaleMode:()=>
		if @gridContainer
			@gridContainer.classList.add "scaleMode"

	whiteOut:()=>
		@element.classList.add "whiteout"

	resize:()=>
		# @createGrid()



module.exports = GridVis