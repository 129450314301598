EventEmitter = require("events").EventEmitter

SongData = require "../score/SongData.coffee"

NEXT_SECTION_THRESHOLD = 3

SECTION_COUNTERS = {
	"a1" : 0
}

class Director extends EventEmitter
	constructor:()->

	init:(@data, @ring, @fx, @shake, @synth, @audio, @waiting, @intro, @outro, @waves, @shapes, @grid, @video)=>

		@currentSection = null

		@ring.on("selected", @onRingItemSelected.bind(this))
		@ring.on("allSelected", @onRingAllSelected.bind(this))
		@fx.on("exit", @onFxExit.bind(this))
		@fx.on("intensityChange", @onFxIntensityChange)

		@shake.on("exit", @onShakeExit.bind(this))
		@shake.on("intensityChange", @onFxIntensityChange)

		@synth.on("exit", @onSynthExit.bind(this))
		@synth.on("intensityChange", @onHeadSynthIntensityChange.bind(this))

		@audio.on("message", @onAudioMessage.bind(this))

		@showingHelper = false
		@lastHelperText = ""

		@reverseFlag = false
		@showingBackButton = false

		document.body.querySelector("#back-button").addEventListener("click", @onBackButtonClick)
		
	start:()=>

		@goToSection("a1")


		# DEBUG OPTIONS

		# @goToSection("b1")

		# @outro.show(()->)
		# @video.showVideo "sectionD2"

		# @waves.show()
		# @waves.createWave("id1", data.angle, data.width, data.index)

		# @audio.sendBang("to-section-B")

		# @fx.show("demo", (=>), false)
		# @shake.show("shake", ()=>)

		# @audio.sendFloat("to-section-C", 0)
		# @synth.show("solo-synth", (()->), false)
		# @video.showVideo "sectionC"

		# @currentSection = "d1"
		# @shake.show("build-up-2", (=>), false, false, true)
		# @shake.showWiggle()
		# @video.showVideo "sectionD"
		# @grid.createGrid()
		# @grid.show()
		# @grid.setColorMode()

		# @waiting.show(()->)
		# @waiting.showTimer(1)

		@audio.reset()

	goBack:()=>
		

	goToSection:(sectionName)=>

		console.log "Going to section #{sectionName}"

		document.body.classList.remove("section-#{@currentSection}")
		document.body.classList.add("section-#{sectionName}")

		previousSection = @currentSection
		@currentSection = sectionName
		section = @data.sections[sectionName]

		@waiting.hide(()->)
		@fx.hide(()=>)
		@shake.hide(()=>)
		@synth.hide(()=>)
		@shapes.hide(()=>)
		clearTimeout(@helperTipTimeout)
		# @waves.removeAllWaves()

		for segment in @ring.topGroup.segments
			segment.hideHelperTip()
		for segment in @ring.bottomGroup.segments
			segment.hideHelperTip()

		if sectionName == "a1"
			@waves.show(()->)

			# show helper tip
			@showHelperTip("Add a sample")

			# hide back button
			@setBackButtonVisibility(false)

			# show background video
			@video.showVideo("sectionA")

		else if sectionName == "b1"
			@shapes.show(()->)

			# show helper tip
			@showHelperTip("Add an effect")

			# show back button
			@setBackButtonVisibility(true)

			# show background video
			@video.showVideo("sectionB")

		if sectionName == "a1" or sectionName == "b1"
			@ring.hide(()=>

				allSelectedThreshold = NEXT_SECTION_THRESHOLD
				if sectionName == "b1" then allSelectedThreshold = 1

				@ring.setTopGroup section.top, allSelectedThreshold
				@ring.setBottomGroup section.bottom, 99

				@ring.show(()=>

					# if we're coming back into b1 from an effect
					if previousSection == "b1"
						@ring.bottomGroup.enable()
				)

			)

		

		
	onRingItemSelected:(data)=>

		console.log "Ring item selected", data

		# manage helper
		# if @showingHelper
		# 	for segment in @ring.topGroup.segments
		# 		segment.hideHelperTip()
		# 	for segment in @ring.bottomGroup.segments
		# 		segment.hideHelperTip()
		# 	@showingHelper = false

		clearTimeout(@helperTipTimeout)

		switch data.type

			when SongData.types.TOGGLE

				# this was a toggle, check the state
				value = data.value
				oscValue = 0
				if value then oscValue = 1

				if !data.playing
					@audio.sendFloat(data.id, oscValue)
					

				if @currentSection == "a1"
					if data.playing
						@waves.createWave(data.id, data.angle, data.width, data.index)
					else if !value
						@waves.removeWaveById(data.id)

					SECTION_COUNTERS["a1"]++

					# add a second helper arrow if it's the first one on section A
					if SECTION_COUNTERS["a1"] == 1
						segments = @ring.topGroup.segments
						nextSegmentIndex = Math.min(segments.length-1, Math.max(1, 1 + Math.floor(Math.random() * segments.length-2)))
						if segments[nextSegmentIndex]
							for segment in @ring.topGroup.segments
								segment.hideHelperTip()
							segments[nextSegmentIndex].showHelperTip("Add more samples")
					# else if SECTION_COUNTERS["a1"] > 1
					# 	

			when SongData.types.FX

				switch data.id

					when "shake"

						# special case, need to show shake input
						@ring.dim(()->)
						@waves.hide(()->)
						@shake.show("build-up", (=>), false, true)

						# enable relevant fx channel
						# @audio.sendFloat("fx-delay-on", 1)

					when "solo-synth"

						# special case, show solo synth input
						@ring.dim(()->)
						# @synth.show "solo-synth", (=>), false

					else
						# this was a FX, show FX ring

						@ring.dim(()->)
						@fx.show(data.id, ()->)

						@audio.sendFloat(data.id + "-on", 1)


			when SongData.types.CUE

				# this was a cue, fire off cue

				switch data.id
					when "reset"

						@audio.reset()
						@goToSection("a1")

						@ring.hide(()=>)
						setTimeout(()=>
							@ring.show(()=>)
						, 500)

					when "end"
						@audio.end()
						@ring.hide(()->)

					when "to-section-A"

						@ring.hide(()=>)
						@waiting.show(()=>)
						@audio.sendFloat("to-section-A", 0)

					when "to-section-B"

						@ring.hide(()=>)
						@waiting.show(()=>)
						@waiting.showTimer(2)
						@audio.sendFloat("to-section-B", 0)

					when "to-section-C"

						@ring.hide(()=>)
						@waiting.show(()=>)
						@waiting.showTimer(2)
						@audio.sendFloat("to-section-C", 0)
						# @setBackButtonVisibility(false)

					else
						@ring.hide(()->)
						@fx.hide(()->)


	onRingItemPlaying:(data)=>

		switch data.type

			when SongData.types.TOGGLE

				if @currentSection == "a1"
					if value
						@waves.createWave(data.id, data.angle, data.width)
					else
						@waves.removeWaveById(data.id)

	onRingAllSelected:(data)=>

		if @currentSection == "a1" and data == "top"
			@ring.bottomGroup.segments[0].enable()
			@ring.bottomGroup.segments[0].showHelperTip("Next section")
			@showingHelper = true

		if @currentSection == "b1" and data == "top"
			@ring.bottomGroup.segments[0].enable()
			@ring.bottomGroup.segments[0].showHelperTip("Go to next section")
			@showingHelper = true


	onAudioMessage:(data)=>

		# received message from audio system

		switch data.address

			when "A-playing"
				if @currentSection != "a1"
					@goToSection("a1")

			when "A-kick-playing"
				if @reverseFlag and data.value
					@reverseFlag = false
					@goToSection("a1")

			when "B-full-main-playing", "B1-full-main-playing"

				if @currentSection != "b1"
					@goToSection("b1")
				else
					console.warn "Already in section b1"

			when "C-full-main-playing", "C1-playing"

				if @currentSection != "c1" and @currentSection != "c2"
					
					if data.address == "C1-playing" then @currentSection = "c1"

					document.body.classList.remove("section-b1")
					document.body.classList.add("section-c1")

					# @emit("enableHead", null)
					
					# @audio.sendFloat("solo-synth-on", 1)
					# @audio.sendFloat("solo-synth-repeat", 0)
					@synth.show("solo-synth", (()->), false)
					@video.showVideo "sectionC"
					@shapes.hide(()->)
					@waiting.hide(()->)

					# show back button
					# @setBackButtonVisibility(false)

				else
					console.warn "Already in section c1"

			when "C3-playing"

				if @synth.visible
					@synth.hide(()=>)
					@video.showVideo "sectionD"
					@grid.createGrid()
					@grid.show()

				# @setBackButtonVisibility(true)
				@waiting.hide(()->)
				# @shake.setText("WIGGLE")
				@shake.showWiggle()
				@shake.show("build-up-2", (=>), false, false, true)
				@grid.locked = false
				@grid.hide(0.5)
				# setTimeout(()=>
				# 	@grid.setColorMode()
				# , 1000)
					

			when "C4-playing"
				@shake.hide(()->)
				@grid.show()				
				@grid.setColorMode()
				@grid.setScaleMode()
				@grid.locked = true
				@grid.setOpacity(1.0)
				@video.showVideo "sectionD2"
				@grid.whiteOut()
				@waiting.show(()=>)
				
				# comedy outro
				@waiting.setText "EPIC OUTRO.", true, "small flash"
				setTimeout(()=>
					@waiting.setText ""
				, (4) * 1000)

				# @waiting.setCountdown(15)
				@waiting.hideTimer()
				@setBackButtonVisibility(false)
				# @waiting.show(()->)

				setTimeout(()=>
					# @waiting.hide(()->)
					@grid.hide(4)
					setTimeout(()=>
						@waiting.hide(()->)
						@outro.show(()->)
					, 2000)
					
				,(16-1) * 1000)


			when "solo-synth-is-repeating"

				if data.value
					@video.showVideo "sectionD"
					@grid.createGrid()
					@grid.show()
					@waiting.show()
					@waiting.setText "WAIT", true
					@waiting.setCountdown(8)
					@waiting.showTimer(10)

			else
				@ring.message(data.address, data.value)


	onFxIntensityChange:(data)=>
		@audio.sendFloat(data.id + "-param", data.intensity)

		# if @grid.visible and not @grid.locked
		# 	@grid.setOpacity(data.intensity)

	onFxExit:(id)=>
		@audio.sendFloat(id + "-on", 0)
		@goToSection(@currentSection)

	onShakeExit:(id)=>

		if id == "build-up"
			@audio.sendFloat("to-section-B", 0)
		else if id == "build-up-2"
			@audio.sendFloat("build-up-2-param", 1.0)
			@grid.setOpacity(1.0)


		@waiting.show(()->)

		# @ring.hide(()->)
		@shake.hide(()->)

	onSynthExit:()=>

		document.body.classList.remove("section-c1")
		@emit("disableHead", null)
		# @audio.sendFloat("solo-synth-repeat", 1)
		@audio.sendBang("to-C2")
		@synth.hide(()=>)
		@setBackButtonVisibility(false)

		# @video.showVideo "sectionD"
		# @grid.createGrid()
		# @grid.show()
		# @waiting.show()
		# @waiting.showTimer(16)
		

	onHeadSynthIntensityChange:(data)=>
		@audio.sendFloat("solo-synth-pitch", data.intensity)

	onBackButtonClick:()=>
		switch @currentSection
			when "b1"
				
				if @fx.visible
					@fx.emit("exit", @fx.id)
					@fx.hide(()=>)
				else
					@reverseFlag = true
					@ring.hide(()=>)
					@waiting.show()
					@audio.sendFloat("to-section-A", 0)
				
			when "c1"
				if @synth.visible
					@synth.hide(()=>)
				@audio.sendFloat("solo-synth-repeat", 0)
				@audio.sendFloat("to-section-B", 0)

		clearTimeout(@helperTipTimeout)

	showHelperTip:(text)=>
		if text is @lastHelperText then return
		@lastHelperText = text

		clearTimeout(@helperTipTimeout)

		@helperTipTimeout = setTimeout(()=>
			@showingHelper = true
			@ring.topGroup.getLeftSegment().showHelperTip(text)
		,2000)

	setBackButtonVisibility:(visible)=>
		@showingBackButton = visible
		if visible
			$("ui").classList.add "show-back-button"
		else
			$("ui").classList.remove "show-back-button"

	connectController:(controller)=>
		controller.on("x", (data)=>
			if @showingBackButton
				if data <= -0.4
					$("back-button").classList.add "hover"
				else
					$("back-button").classList.remove "hover"
			)


		controller.on("click", (data)=>
			# console.warn data.relX
			if data.relX < -0.4 and @showingBackButton
				@onBackButtonClick()
			)


module.exports = new Director()