EventEmitter = require("events").EventEmitter
Utils = require "../utils/Utils.coffee"
io = require "socket.io-client"

CODE_LENGTH = 4

class PhoneController extends EventEmitter
	constructor:(@container)->

		@marker = @container.querySelector("#userMarker")
		@markerHighlight = @marker.querySelector("#markerHighlight")
		@markerDebug = @container.querySelector("#markerDebug")

		@appContainer = document.body.querySelector("#svg-container")
		@enabled = false
		@safeZone = true
		@started = false

		@vScale = 50.0
		@hScale = 25.0

		@screenX = 0
		@screenY = 0

		@lastMotionData = null
		@lastRelX = 0

		@displaySize = { w : window.innerWidth, h : window.innerHeight }

		@clickTimeoutId = -1

	init:(@socket)->

		@gameId = @createGameId()
		# @gameId = "BLURB"
		# @gameId = "TEST"

		if (typeof(io) == "undefined")
			console.error "Socket.IO not loaded"
			return

		# @socket = io.connect((document.location.hostname).split(':')[0] + ":8080")
		# @socket = io.connect((location.host || 'localhost').split(':')[0] + ":8080")
		# @socket.on("connection", ()=>
		# 	@socket.emit("identifyBrowser", @gameId)
		# 	)
		@socket.emit("identifyBrowser", @gameId)
		
		@socket.on("start", ()=>
			if @started then return
			else 
				@started = true
				@emit("start", null)
			)

		@socket.on("motion", @onMotionEvent.bind(this))
		@socket.on("click", @onClickEvent.bind(this))
		@socket.on("signal", @onSignalEvent.bind(this))
		@socket.on("errorMessage", (data)=>
			@emit("error", data)

			if (data.code == "exists")
				@gameId = @createGameId()
				@emit "codeChange", @gameId
				@socket.emit "identifyBrowser", @gameId
			)

	onMotionEvent:(data)=>

		if not @enabled then return
		
		@lastMotionData = data

		# console.log data.alpha

		relX = ((data.alpha / -@vScale))
		relY = ((data.beta  / -@hScale))

		# console.log "#{relX}, #{relY}"

		@marker.style.left = (50 + (relX * 50)) + "%"
		@marker.style.top = (50 + (relY * 50)) + "%"

		@screenX = @displaySize.w * (0.5 + (relX * 0.5))
		@screenY = @displaySize.h * (0.5 + (relY * 0.5))

		@screenX = @screenX - @displaySize.w/2.0
		@screenY = @screenY - @displaySize.h/2.0

		@length = Math.sqrt(Math.pow(@screenX, 2) + Math.pow(@screenY,2))
		@angle = Math.atan((@screenX) / (@screenY)) * (180 / Math.PI)

		@marker.style.transform = "rotate(#{Math.floor(data.gamma)}deg)"

		# length = Math.sqrt(Math.pow(relX, 2) + Math.pow(relY,2))
		# angle = Math.atan((relX) / (relY)) * (180 / Math.PI)

		# convert to svg angle space
		@angle += 90
		if (relY < 0)
			@angle *= -1
		else
			@angle = Math.abs(@angle - 180)

		# console.log "angle : #{@angle} length : #{@length}"


		# @markerDebug.style.transform = "rotate(#{@angle}deg)"
		# @markerDebug.style.width = length * 50 + "px"

		if @length > 0.1
			if @safeZone
				@emit("active", true)
				@safeZone = false
				console.log "Phone control active"			
			@emit "angle", @angle
		else
			if !@safeZone
				@emit("active", false)
				@safeZone = true
				console.log "Phone control stop"

		xVal = relX * 0.5
		xVal = Math.min(0.5, xVal)
		@lastRelX = Math.max(-0.5, xVal)

		# console.log "x : #{@lastRelX}"
		@emit "x", @lastRelX

		# console.log "gamma : #{data.gamma}"
		@emit "gamma", data.gamma

	onClickEvent:(clickData)=>
		console.log "Click from phone"
		@emit "click", { angle : @angle, length: @length, relX : @lastRelX }

		@markerHighlight.classList.add "click"
		clearTimeout(@clickTimeoutId)
		@clickTimeoutId = setTimeout(()=>
			@markerHighlight.classList.remove "click"
		,500)

	onSignalEvent:(signalData)=>
		console.log "Signal from phone : #{signalData}"
		@emit "signal", signalData

		switch signalData
			when "center"
				console.log "Phone center reset"
			when "side"
				if @lastMotionData
					
					@vScale = @lastMotionData.alpha * 2.2
					console.log  "setting vScale to #{@vScale}"
			when "top"
				if @lastMotionData
					@hScale = @lastMotionData.beta * 2.2
					console.log  "setting vScale to #{@hScale}"


	createGameId:()=>

		text = ""
		possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

		for i in [0..CODE_LENGTH-1] by 1
			text += possible.charAt(Math.floor(Math.random() * possible.length))

		return text



	start:()->
		@enabled = true

	stop:()->
		@enabled = false


	resize:()->
		@displaySize = { w : window.innerWidth, h : window.innerHeight }







module.exports = PhoneController