EventEmitter = require("events").EventEmitter


class ControllerBase extends EventEmitter
	constructor:(@snap)->

	addToDOM:()=>
		try 
			$("svg-container").appendChild(@s.node)
		catch error
			console.error error
		

	removeFromDOM:()=>
		try
			if @s.node.parentElement
				@s.node.parentElement.removeChild(@s.node)
		catch error
			console.error error












module.exports = ControllerBase