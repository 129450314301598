EventEmitter = require("events").EventEmitter
RingSegment = require "./RingSegment.coffee"

Utils = require "../utils/Utils.coffee"

class RingGroup extends EventEmitter
	constructor:(@s, @data, @top, @numSegments, @outerRadius, @innerRadius, @markerRadius, @allSelectedThreshold = 2)->

		@enabled = true

		@element = @s.g()
		@element.attr({
			class : "ringGroup"
			})

		@s.add @element

		@circleGroup = @s.g()
		@element.add @circleGroup

		startAngle = 0
		if @top then startAngle = 0 else startAngle = 180

		# create segments

		@iconGroup = @s.g()
		@iconArray = []
		@segments = []

		markerAngle = Utils.rd Math.atan (@markerRadius * 1.4) / @outerRadius

		for i in [0..@numSegments-1] by 1

			segmentGroup = @s.g().attr({ class : "segmentGroup" })

			pathString = ""

			segmentStartAngle = (startAngle - (180.0 / @numSegments) * i) - 0.5
			segmentEndAngle = (startAngle - (180.0 / @numSegments) * (i + 1)) + 0.5
			midAngle = (segmentStartAngle + (segmentEndAngle - segmentStartAngle)/2)
			
			markerStartAngle = midAngle - markerAngle
			markerEndAngle = midAngle + markerAngle


			# highlight

			innerStart = Utils.cartPolar segmentStartAngle, @innerRadius
			innerEnd = Utils.cartPolar segmentEndAngle, @innerRadius
			posStart = Utils.cartPolar segmentStartAngle, @outerRadius
			posEnd = Utils.cartPolar segmentEndAngle, @outerRadius

			markerStart = Utils.cartPolar markerStartAngle, @outerRadius
			markerEnd = Utils.cartPolar markerEndAngle, @outerRadius
			
			posControlPoint = Utils.cartPolar midAngle, @outerRadius * 1.2

			# pathString += "M 500 500 "
			pathString += "M #{500 + innerStart.x} #{500 + innerStart.y} "
			pathString += "A #{@innerRadius},#{@innerRadius} 0 0,0 #{500 + innerEnd.x}, #{500 + innerEnd.y}"
			pathString += "L " + (500 + posEnd.x) + " " + (500 + posEnd.y) + " "
			# # pathString += "L " + (500 + posEnd.x) + " " + (500 + posEnd.y) + " "
			pathString += "A #{@outerRadius},#{@outerRadius} 0 0,1 #{500 + markerStart.x}, #{500 + markerStart.y}"
			
			pathString += "A #{@markerRadius},#{@markerRadius} 0 0,0 #{500 + markerEnd.x}, #{500 + markerEnd.y}"
			pathString += "A #{@outerRadius},#{@outerRadius} 0 0,1 #{500 + posStart.x}, #{500 + posStart.y}"
			pathString += "L #{500 + innerStart.x} #{500 + innerStart.y} "
			pathString += "Z"

			highlightPath = @s.path(pathString).attr({ fill : "rgba(255,255,255,0.2)"})
			highlightPath.attr({
				class : "segmentHighlight"
				})

			segmentGroup.add highlightPath

			# hit area

			hitAreaStart = Utils.cartPolar segmentStartAngle, @outerRadius * 1.2
			hitAreaMid1 = Utils.cartPolar segmentStartAngle + ((segmentEndAngle-segmentStartAngle) * 0.25), @outerRadius * 1.2
			hitAreaMid2 = Utils.cartPolar segmentStartAngle + ((segmentEndAngle-segmentStartAngle) * 0.5), @outerRadius * 1.2
			hitAreaMid3 = Utils.cartPolar segmentStartAngle + ((segmentEndAngle-segmentStartAngle) * 0.75), @outerRadius * 1.2
			hitAreaEnd = Utils.cartPolar segmentEndAngle, @outerRadius * 1.2

			pathString = "M #{500 + innerStart.x} #{500 + innerStart.y} "
			pathString += "L #{500 + hitAreaStart.x} #{500 + hitAreaStart.y}"
			pathString += "L #{500 + hitAreaMid1.x} #{500 + hitAreaMid1.y}"
			pathString += "L #{500 + hitAreaMid2.x} #{500 + hitAreaMid2.y}"
			pathString += "L #{500 + hitAreaMid3.x} #{500 + hitAreaMid3.y}"
			pathString += "L #{500 + hitAreaEnd.x} #{500 + hitAreaEnd.y}"
			pathString += "L #{500 + innerEnd.x} #{500 + innerEnd.y}"
			pathString += "Z"

			hitArea = @s.path(pathString).attr({ class : "hitArea" })

			segmentGroup.add hitArea


			# keyline

			keylineOuter = @s.g().attr({ class : "keylineOuter" })
			keyline = @s.path(pathString).attr({ fill : "none", stroke : "#fff", class : "keyline"})
			keylineOuter.add keyline

			segmentGroup.add keylineOuter

			# outer line

			pathString = "M #{500 + posStart.x} #{500 + posStart.y}"
			pathString += "A #{@outerRadius} #{@outerRadius} 0 0,0 #{500 + markerEnd.x} #{500 + markerEnd.y}"
			pathString += "M #{500 + posEnd.x} #{500 + posEnd.y}"
			pathString += "A #{@outerRadius} #{@outerRadius} 0 0,1 #{500 + markerStart.x} #{500 + markerStart.y}"

			outerPath = @s.path(pathString).attr({ fill : "none", stroke : "#fff"})
			segmentGroup.add outerPath

			# inner line

			pathString += "M #{500 + innerStart.x} #{500 + innerStart.y}"
			pathString += "A #{@innerRadius},#{@innerRadius} 0 0,0 #{500 + innerEnd.x}, #{500 + innerEnd.y}"

			innerPath = @s.path(pathString).attr({ fill : "none", stroke : "rgba(255,255,255,0.2)"})
			segmentGroup.add innerPath

			markerPos = Utils.cartPolar (startAngle - (180.0 / @numSegments) * (i + 0.5)), @outerRadius

			iconContainer = @s.g()
			iconOuter = @s.circle 500 + markerPos.x, 500 + markerPos.y, @markerRadius * 1.2
			iconOuter.attr({
				fill : "none"
				stroke : "rgba(255,255,255,0.8)"
				class : "iconOuter"
			})

			iconContainer.add iconOuter
			iconContainer.attr({
				class : "iconContainer"
			})

			iconName = @data[i].icon
			icon = @s.image("assets/images/icons/icon-#{iconName}.svg", 500 + markerPos.x - @markerRadius, 500 + markerPos.y - @markerRadius, @markerRadius * 2, @markerRadius * 2)
			iconContainer.add icon

			# TODO : add SVG icon
			@iconArray.push iconContainer
			# @iconGroup.add iconContainer

			segmentGroup.add iconContainer

			@data[i].index = i

			newSegment = new RingSegment(@s, segmentGroup, iconContainer, @data[i], 500 + markerPos.x, 500 + markerPos.y, segmentStartAngle, segmentEndAngle, keylineOuter)
			@segments.push newSegment

			newSegment.on("selected", (data)=>

				@emit("selected", data)

				# this is now a lie, numSelected needs to be only above @allSelectedThreshold to trigger 'allSelected'
				numSelected = 0
				for i in [0..@numSegments-1] by 1
					if @segments[i].value then numSelected++

				if numSelected == @allSelectedThreshold
					console.log "RingGroup : all selected"
					@emit("allSelected", null)
			)

			newSegment.on("hover", (data)=>
				@emit("hover", data)
				)

			newSegment.on("deselect", (data)=>
				@emit("deselect", data)
				)

			@element.add segmentGroup

	
		@clickMask = @s.circle 500, 500, @innerRadius
		@clickMask.attr({
			fill : "#fff"
			"fill-opacity" : 0
		})

		# DEBUG
		# if @top and @segments.length > 0
		# 	@segments[@segments.length-1].showHelperTip("Helper Tip Text")


	show:(callback)->

		@enabled = true

		setTimeout(()=>
			for segment in @segments
				segment.restoreFromData()

		, 500)
		

		callback()

	hide:(callback)->

		callback()

	enable:()->
		for segment in @segments
			segment.enable()

	deselect:()->
		for segment in @segments
			segment.deselect()

	selectByAngle:(angle, click=false)->

		if not @enabled then return

		for segment in @segments
			segment.selectByAngle(angle, click)

	destroy:()->
		@element.remove()

	message:(address, value)=>
		for seg in @segments
			seg.message address, value

	getNode:()->
		return @element

	getLeftSegment:()=>
		return @segments[@segments.length-1]


module.exports = RingGroup