App = require "./app/App.coffee"


#navigator.getUserMedia  = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

Main = new ->
	initialized = false
	app = null
	stats = null
	queryParams = null
	useMouse = true
	debug = false

	init = () ->
		if !initialized
			initialized = true

			queryParams = parseQueryString()

			if queryParams["debug"] then debug = true

			app = new App($('#app'))

			animate()
			

	animate = () ->
		requestAnimationFrame animate
		

	# --- HELPERS ---------

	

	parseQueryString = () ->
		queryString = document.location.href
		queryIndex = queryString.indexOf("?")
		queryStringArray = []

		if queryIndex != -1 && queryIndex+1 != queryString.length
			queryString = queryString.substring(queryIndex+1, queryString.length)
			queryStringArray = queryString.split("&")

		returnObject = {}
		for i in [0..queryStringArray.length-1] by 1
			tempArray = queryStringArray[i].split("=")
			val = true
			if (typeof(tempArray[1]) != "undefined") then val = tempArray[1]
			returnObject[tempArray[0]] = val

		return returnObject

	getQueryParams = () ->
		return queryParams

	setCSSProps = ( e, props ) ->
		for k,v of props
			e.style[k] = v

	return {
		init: init
		debug : debug
		useMouse : useMouse
		getQueryParams : getQueryParams
	}

# globals
globals =

	$ : ( id ) ->
		return document.getElementById id

	el : ( type, cls ) ->
		e = document.createElement type
		if ( cls ) 
			e.className = cls;
		return e

	Main: Main

for k,v of globals
	window[k] = v


# #launch
# if window.addEventListener
# 	window.addEventListener 'load', ( e ) ->
# 		Main.init()
# 	, false
# else if window.attachEvent
# 	window.attachEvent 'load', ( e ) ->
# 		Main.init()
# 	, false