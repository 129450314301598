AudioInterface = require "./AudioInterface.coffee"

SAMPLE_LIST = [
	"A-bass",
	"A-kick",
	"A-perc",
	"A-synths",
	"A-uke",
	"A-vox",
	"A-outro",
	"B-outro",
	"B1-full-main",
	"B2-full-transition",
	"C1",
	"C2",
	"C3",
	"C4"
]

NUM_SAMPLES = SAMPLE_LIST.length


class HeavyAudioInterface extends AudioInterface
	constructor:()->

		@context = new (window.AudioContext || window.webkitAudioContext)
		
	
	init:()->

		@loaded = false

		@gainNode = @context.createGain()
		@gainNode.connect @context.destination
		@gainNode.gain.value = 0
		
		

		@rq = new XMLHttpRequest()
		@rq.onload = @_rqOnLoad
		

		@bufferLib = []
		@loadNextAudio()

		
		
	
	initPatch:(hqMode)=>




		@blockSize = 2048
		if !hqMode then @blockSize = 4096

		@patch = new headsynthLib({
			sampleRate : @context.sampleRate,
			blockSize : @blockSize
			printHook : @_printHook
			sendHook : @_sendHook
			})

		# for debugging
		window.headsynth = @patch

		@processor = @context.createScriptProcessor(
			@blockSize,
			@patch.getNumInputChannels(),
			Math.max(@patch.getNumOutputChannels(), 1)
			)

		@processor.onaudioprocess = (e)=>
			@patch.process(e)

		pushNextBuffer = ()=>

			if (@bufferLib.length)
				next = @bufferLib.shift()
				@_sendBufferToHeavy(next.id, next.buffer)
				pushNextBuffer()
			else
				@processor.connect @gainNode


		pushNextBuffer()

		@patch.sendFloatToReceiver("tick-on", 1.0);

	loadNextAudio:()=>
		if SAMPLE_LIST.length
			@currentSample = SAMPLE_LIST.pop()
			url = "./assets/audio/" + @currentSample + ".mp3" + "?rnd=" + Math.floor(Math.random() * 10000)
			@rq.open("GET", url, true)
			@rq.responseType = "arraybuffer"
			@rq.send()

		else
			@loaded = true			
			@emit "loaded", null

	_rqOnLoad:()=>
		audioData = @rq.response
		@context.decodeAudioData(audioData, (buffer)=>
			# console.log "Finished loading #{@currentSample}"
			# console.log "normal length "
			if buffer.sampleRate != @context.sampleRate
				@_convertSample(buffer, (resampledBuffer)=>
					@bufferLib.push({
							id : @currentSample
							buffer : resampledBuffer
						})
						# @_sendBufferToHeavy(resampledBuffer)
					@loadNextAudio()
				)
			else
				# Send to Heavy
				@bufferLib.push(
					{
						id : @currentSample
						buffer : buffer
					})
				# @_sendBufferToHeavy(buffer)
				@loadNextAudio()
			)

		sampleLoadProgress = 1.0 - (SAMPLE_LIST.length / NUM_SAMPLES)
		@emit "loadProgress", sampleLoadProgress

	_sendBufferToHeavy:(id, buffer)=>
		table = @patch.getTableForName(id + "-1")
		table.setBufferWithData(buffer.getChannelData(0))
		table = @patch.getTableForName(id + "-2")
		table.setBufferWithData(buffer.getChannelData(1))

		# if (buffer.numberOfChannels > 0)
		# 	@patch.fillTableWithFloatBuffer(id + "-1", buffer.getChannelData(0))
		# if (buffer.numberOfChannels > 1)
		# 	@patch.fillTableWithFloatBuffer(id + "-2", buffer.getChannelData(1))

	_convertSample:(buffer, callback)=>
		# console.log "Resampling #{@currentSample} from #{buffer.sampleRate} to #{@context.sampleRate}"
		resamplingRate = @context.sampleRate / buffer.sampleRate
		outputLength = buffer.length * resamplingRate
		
		oc = new (window.OfflineAudioContext || window.webkitOfflineAudioContext)(2, Math.floor(outputLength), @context.sampleRate)
		source = oc.createBufferSource()
		source.buffer = buffer
		source.connect(oc.destination)
		source.start()
		oc.startRendering().then((newBuffer)=>


			callback(newBuffer)
			)


	reset:()->

		if not Main.getQueryParams()["mute"]
			@gainNode.gain.value = 1.0

		# go back to kick drum
		@sendFloat("A-kick-on", 1)
		# @sendFloat("A-reset", 1)
		@sendFloat("reset-all", 1)
		# @sendFloat("to-section-A", 1)
		

		@sendFloat("fx-delay-on", 0)
		@sendFloat("fx-reverse-on", 0)
		@sendFloat("fx-filter-on", 0)

	_printHook:(message)=>
		# console.log message

		parts = message.split(" ")
		address = parts[1].replace(/\:/g, "")
		value = parseFloat(parts[2])

		# console.log "message from heavy; address : #{address}, value : #{value}"
		
		@emit "message", { address : address, value : value }

	_sendHook:(message)=>
		# console.log message

		# (0.00) A-uke-playing: 1

		parts = message.split(" ")
		address = parts[1].replace(/\:/g, "")
		value = parseFloat(parts[2])

		# console.log "message from heavy; address : #{address}, value : #{value}"
		
		@emit "message", { address : address, value : value }

	backToStart:()=>
		@sendFloat("to-section-A", 1)

	end:()->

		# play ending
		@sendFloat("to-end", 1)

	sendBang:(inlet)->

		if not @loaded 
			console.error "tried to send bang to inlet #{inlet} before patch was loaded"
			return

		console.log "sending bang to #{inlet}"
		@patch.sendBangToReceiver inlet

		# inlet = "/" + inlet

		# # console.log "sending osc bang to #{inlet}" 

		# # send bang to pd to given inlet
		# @ioSocket.emit("message", { address : inlet, value : "bang"} )
			

	sendFloat:(inlet, value)->

		if not @loaded 
			console.error "tried to send float to inlet #{inlet} before patch was loaded"
			return

		#  console.log "sending float #{value} to #{inlet}"
		@patch.sendFloatToReceiver inlet, value

		# if value == null then return

		# inlet = "/" + inlet

		console.log "sending osc float to #{inlet}, value #{value}" 

		# # send float to given inlet
		# @ioSocket.emit("message", { address : inlet, value : value } )



module.exports = HeavyAudioInterface