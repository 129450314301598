EventEmitter = require("events").EventEmitter
Utils = require "../utils/Utils.coffee"
SongData = require "../score/SongData.coffee"

HIGHLIGHT_DELAY = 1000

class RingSegment extends EventEmitter
	constructor:(@s, @segmentElement, @iconElement, @data, @cX, @cY, @startAng, @endAng, @keyline)->

		console.log "segment #{@data.id} created between angles #{@startAng} and #{@endAng}"

		@centerAngle = @startAng + (@endAng - @startAng)/2
		@angleWidth = Math.abs(@startAng - @endAng)

		@iconOuter = @iconElement.node.querySelector(".iconOuter")

		@selectTimeoutId = -1
		@selecting = false
		@enabled = true
		@playing = false # flag for 'stem' selection

		@helperTipHideTimeout = -1

		if Main.useMouse
			@segmentElement.node.addEventListener("mouseover", @select.bind(this))
			@segmentElement.node.addEventListener("mouseout", @deselect.bind(this))
			@segmentElement.node.addEventListener("click", @onClick.bind(this))

		# DEBUG show OSC endpoint in icon

		@debugText = @s.text(@cX,@cY,@data.id.toUpperCase())
		@debugText.prependTo(@iconElement.node)
		@debugText.attr({
			class : "debugText"
			})

		@value = false
		@reporting = true

		@segmentElement.addClass "index-#{@data.index}"


	showHelperTip:(text)=>

		clearTimeout(@helperTipHideTimeout)

		if @helperGroup then return

		arrowPoint = Utils.cartPolar @centerAngle, 360
		arrowTipTop = Utils.cartPolar @centerAngle-1, 370
		arrowTipBottom = Utils.cartPolar @centerAngle+1, 370
		arrowEnd = Utils.cartPolar @centerAngle, 400
		textLocation = Utils.cartPolar @centerAngle, 420

		arrowPath = "M #{500 + arrowPoint.x} #{500 + arrowPoint.y}"
		arrowPath += "L #{500 + arrowTipTop.x} #{500 + arrowTipTop.y}"
		arrowPath += "M #{500 + arrowTipBottom.x} #{500 + arrowTipBottom.y}"
		arrowPath += "L #{500 + arrowPoint.x} #{500 + arrowPoint.y}"
		arrowPath += "L #{500 + arrowEnd.x} #{500 + arrowEnd.y}"

		@helperGroup = @s.g().attr({ class : "helperGroup" })

		@helperGroup.add @s.path(arrowPath).attr({ class : "helperArrow" })
		helperText = @s.text(500 + textLocation.x, 500 + textLocation.y, text).attr({ class : "helperText" })
		@helperGroup.add helperText

		# console.log "centerAngle = #{@centerAngle}"

		# anchor text left if it's on the right
		if @centerAngle >= -90
			helperText.addClass "anchorLeft"

		setTimeout(()=>
			@helperGroup.addClass("visible")
		,200)

	hideHelperTip:()=>

		clearTimeout(@helperTipHideTimeout)

		if @helperGroup
			@helperGroup.removeClass("visible")
			@helperTipHideTimeout = setTimeout(()=>
				if @helperGroup
					@helperGroup.remove()
					@helperGroup = null
			,500)
			


	setMarkerMask:(@markerMask)=>

	restoreFromData:()=>
		if @data.selected
			@select()
			@onClick()

	enable:()=>

		@enabled = true

		@segmentElement.addClass "enabled"

		# if @markerMask
		# 	@markerMask.node.style.display = "block"

		# bbox = @iconElement.getBBox()
		# @iconElement.animate( {
		# 	transform : "s1,1," + bbox.cx + "," + bbox.cy
		# 	}, 200, mina.easeIn)

		# @segmentElement.addClass("attention")
			

		@iconElement.node.style.display = "block"

	disable:()=>
		# @enabled = false
		# @iconElement.node.style.display = "none"
		
		# if @markerMask
		# 	@markerMask.node.style.display = "none"

		# @segmentElement.removeClass("attention")

		@segmentElement.removeClass "enabled"

	select:()=>
		if @selecting then return
		if not @enabled then return
		
		@selecting = true
		# @iconOuter.classList.add("select")
		@segmentElement.addClass("hover")
		clearTimeout @selectTimeoutId
		# @selectTimeoutId = setTimeout(@onClick, HIGHLIGHT_DELAY)

		if @reporting
			@emit("hover", @data)

	selectByAngle:(angle, click=false)=>

		if not @enabled then return

		if angle < Math.max(@startAng, @endAng) and angle > Math.min(@endAng, @startAng)
			@select()
			if click then @onClick()
		else
			@deselect()

		


	deselect:()=>

		if not @selecting then return

		@selecting = false
		@reporting = true
		@iconOuter.classList.remove("select")
		@segmentElement.removeClass("hover")
		clearTimeout @selectTimeoutId

		if @keyline
			@keyline.removeClass("visible")

		@emit("deselect", @data)

	onClick:()=>
		@selecting = false
		@reporting = false
		if @data.type is SongData.types.TOGGLE
			@value = !@value
			@data.selected = @value
			if @playing and !@value then @playing = false
		else
			@value = true # value always becomes true; we use this to indicate the user has visited this option

			# TODO : change segment to reflect selected / unselected state

		@emit("selected", { id : @data.id, type : @data.type, value : @value, label : @data.label, angle : @centerAngle, width : @angleWidth, playing : @playing, index : @data.index })

		if @keyline
			@keyline.addClass("visible")

		if @helperGroup then @hideHelperTip()

	message:(address, value)=>
		# check if this message is meant for us
		if address is @data.listen and @data.type is SongData.types.TOGGLE

			if value == 1
				console.log "Toggle segment #{address} is now playing"
				@segmentElement.removeClass("cued")
				@segmentElement.addClass("active")
				@iconOuter.classList.add("select")
				@playing = true
				@emit "selected", { id : @data.id, type : @data.type, value : @value, label : @data.label, angle : @centerAngle, width : @angleWidth, playing : @playing, index : @data.index }

			if value == 0.5
				@playing = false
				console.log "Toggle segment #{address} is now cued"
				@segmentElement.addClass("cued")
				@segmentElement.removeClass("active")
				@iconOuter.classList.add("select")
			if value == 0
				@playing = false
				console.log "Toggle segment #{address} is now off"
				@segmentElement.removeClass("cued")
				@segmentElement.removeClass("active")
				@iconOuter.classList.remove("select")


			console.log "segment listening to #{address} with value #{value}"
	


module.exports = RingSegment