FxController = require "./FxController.coffee"
Utils = require "../utils/Utils.coffee"

PITCH_MARKER_HEIGHT = 50
PITCH_MARKER_WIDTH = 30

MAX_MOVE = 1000


SOLO_TIMEOUT = 1 * 4 * 0.5 * 1000

class SynthController extends FxController
	constructor:(@snap)->
		super

		@markerPos = 0
		@showingRecord = false

		@colorFlip = false

		# @bar_timeout = SOLO_TIMEOUT
		@recordStartTimeoutId = -1

	init:()=>
		super

		@s.addClass("synthController")

		# remove outer & inner circles
		@outerCircle.remove()
		@altCircle.remove()
		@timerGroup.remove()


		@particleMarkers = []
		@particleGroup = @s.g()
		for i in [0..10] by 1

			side = "top"
			if i % 2 == 0
				side = "bottom"

			marker = @createMarker(PITCH_MARKER_WIDTH * (0.2 + (Math.random() * 0.3)), PITCH_MARKER_HEIGHT * (0.2 + (Math.random() * 0.3)), "particleMarker #{side}")
			@particleMarkers.push marker

			marker.node.addEventListener("animationend", (event)=>
				
				if @visible
					event.target.classList.remove("animate")

					@markerPos = (@intensity - 0.5) * MAX_MOVE
					event.target.parentNode.style.transform = "translateX(#{@markerPos}px)"

				setTimeout(()=>
					event.target.classList.add("animate")
				,5)
				
				)

			marker.addClass("animate")
			markerOuter = @s.g().attr({ class : "markerOuter" })
			markerOuter.add marker
			@particleGroup.add markerOuter


		
		@innerMarker = @createMarker(PITCH_MARKER_WIDTH, PITCH_MARKER_HEIGHT, "pitchMarkerInner")
		@outerMarker = @createMarker(PITCH_MARKER_WIDTH * 1.5, PITCH_MARKER_HEIGHT * 1.5, "pitchMarkerOuter")

		@pitchMarker = @s.g().attr({ class : "pitchMarker" })
		@pitchMarker.add @innerMarker
		@pitchMarker.add @outerMarker

		@g.add @pitchMarker

		# side markers

		controllerRadius = 560

		leftAngle = 0


		leftStart = Utils.cartPolar(leftAngle - 10, controllerRadius)
		leftEnd = Utils.cartPolar(leftAngle + 10, controllerRadius)

		rightStart = Utils.cartPolar(leftAngle - 180 -10, controllerRadius)
		rightEnd = Utils.cartPolar(leftAngle - 180 + 10, controllerRadius)

		pathString = "M #{500 + leftStart.x} #{500 + leftStart.y}"
		pathString += "A #{controllerRadius} #{controllerRadius} 0 0,1 #{500 + leftEnd.x} #{500 + leftEnd.y}"
		pathString += "M #{500 + rightStart.x} #{500 + rightStart.y}"
		pathString += "A #{controllerRadius} #{controllerRadius} 0 0,1 #{500 + rightEnd.x} #{500 + rightEnd.y}"

		# circle animations

		@outerCircle = @s.circle(500,500,300).attr({ class : "outerCircle" })
		@innerCircle = @s.circle(500,500,300).attr({ class : "innerCircle" })

		@g.add @outerCircle
		@g.add @innerCircle

		# side markers

		pathString = "M 0 500"
		pathString += "L 70 430"
		pathString += "M 0 500"
		pathString += "L 70 570"

		pathString += "M 1000 500"
		pathString += "L 930 430"
		pathString += "M 1000 500"
		pathString += "L 930 570"

		@sideMarker = @s.path(pathString).attr({ class : "sideMarker"})
		@g.add @sideMarker

		# diamond marker

		# @pitchMarker = @s.g().attr( { class : "pitchMarker" })
		# # @pitchMarker.transform().globalMatrix.scale(0.5,0.5,500,500)
		# @pitchMarker.transform('s0.5,0.5 t500,500')

		# @markerA = @s.g().attr( { class : "synthMarkerPart" })
		# @markerA.node.innerHTML = DIAMOND_A_SVG

		# @markerB = @s.g().attr( { class : "synthMarkerPart" })
		# @markerB.node.innerHTML = DIAMOND_B_SVG

		# @pitchMarker.add @markerA
		# @pitchMarker.add @markerB

		# @g.add @pitchMarker

		# recording prompt

		@prompt = @s.g().attr({ class : "recordingPrompt" })
		@g.add @prompt
		
		@recordPromptText = @s.text(500, 820, "CLICK TO START RECORDING")
		@prompt.add @recordPromptText

		@smallTimer.attr({ cy : "860" })

		@intensity = 1.0

	createMarker:(width, height, className)=>

		pathString = "M #{500} #{500 + height}"
		pathString += "L #{500 + width} 500"
		pathString += "L #{500} #{500 - height}"
		pathString += "L #{500 - width} 500"
		pathString += "Z"

		marker = @s.path(pathString).attr({ class : "marker #{className}" })

	show:(@id, callback, autoExit=true)=>
		super

		@intensity = 1.0

		# update text
		@twistText.node.innerHTML = "PLAY THE SYNTH"

		# @twistText.removeClass("visible")
		@g.add @twistText
		@twistText.removeClass("attention")

		clearTimeout(@updateTimeoutId)
		@updateTimeoutId = setInterval(@update, 50)

		clearTimeout(@recordStartTimeoutId)

		setTimeout(()=>
			@smallTimer.addClass("animate")
		, 500)
		

		@recordStartTimeoutId = setTimeout(()=>
			@showRecord()
		, SOLO_TIMEOUT * 8)

		@flipColor()

	showRecord:()=>

		@prompt.addClass("visible")
		@s.addClass("recordReady")
		@smallTimer.removeClass("animate")
		
		@showingRecord = true

		onClickHandler = ()=>
			@s.node.removeEventListener("click", onClickHandler)
			@recordStart()

		@s.node.addEventListener("click", onClickHandler)

	recordStart:()=>

		console.log "Solo synth recording started"

		@recordPromptText.node.innerHTML = "RECORDING"

		@s.removeClass("recordReady")
		@s.addClass("recording")
		@smallTimer.addClass("animate")

		@innerMarker.addClass("recording")
		@sideMarker.addClass("recording")


		@emit("recordStart", null)

		setTimeout(()=>
			@emit("exit", null)
			@hide()

		,SOLO_TIMEOUT)

	hide: ()=>
		super

		clearTimeout(@recordStartTimeoutId)
		
	update:()=>

		if @pitchMarker

			@markerPos = (@intensity - 0.5) * MAX_MOVE
			@pitchMarker.node.style.transform = "translateX(#{@markerPos}px)"

	connectController:(@controller)=>
		super

		@controller.removeListener("gamma", @onControllerTwist)

	onControllerMove:(value)=>

		if not @visible then return

		@intensity = value + 0.5
		@intensity = Math.min(1.0, @intensity)
		@intensity = Math.max(0, @intensity)

		@emit("intensityChange", { id : @id, intensity : @intensity })

	onControllerClick:()=>

		if @showingRecord
			@recordStart()
			@showingRecord = false

	onControllerTwist:(value)=>
		# do nothing
		return

	flipColor:()=>
		if @visible
			setTimeout(@flipColor, 4000)

		if @colorFlip
			@s.removeClass "colorFlip"
		else
			@s.addClass "colorFlip"

		@colorFlip = !@colorFlip

DIAMOND_A_SVG = '<g>
	<g>
		<polygon points="873.4,625 124.9,625 250.9,751 747.4,751 		"/>
	</g>
	<g>
		<polygon points="249.1,251 749.1,251 624.1,126 374.1,126 		"/>
	</g>
	<g>
		<polygon points="375.9,876 499.1,999.2 622.4,876 		"/>
	</g>
	<g>
		<polygon points="124.1,376 0.1,500 998.1,500 874.1,376 		"/>
	</g>
</g>'

DIAMOND_B_SVG = '<g>
	<g>
		<polygon points="249.9,750 375.9,876 622.4,876 748.4,750 		"/>
	</g>
	<g>
		<polygon points="0.9,501 124.9,625 873.4,625 997.4,501 		"/>
	</g>
	<g>
		<polygon points="624.1,126 499.1,1 374.1,126 		"/>
	</g>
	<g>
		<polygon points="124.1,376 874.1,376 749.1,251 249.1,251 		"/>
	</g>
</g>'





module.exports = SynthController