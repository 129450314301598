EventEmitter = require("events").EventEmitter


VIDEO_WIDTH = 320
VIDEO_HEIGHT = 240

class Headtracker extends EventEmitter
	constructor:(@container)->

		@marker = @container.querySelector("#userMarker")
		@appContainer = document.body.querySelector("#svg-container")
		@started = false
		@safeZone = true

	init:()->

		@video = document.createElement("video")
		@video.setAttribute("autoplay", "")
		@video.setAttribute("loop", "")

		@canvas = document.createElement("canvas")
		@canvas.width = VIDEO_WIDTH
		@canvas.height = VIDEO_HEIGHT

		@debugCanvas = document.createElement("canvas")
		@debugCanvas.width = VIDEO_WIDTH
		@debugCanvas.height = VIDEO_HEIGHT

		@htracker = new headtrackr.Tracker({ ui : true, debug : @debugCanvas})
		@htracker.init(@video, @canvas)

		

		document.addEventListener("headtrackingEvent", @onHeadTrackingEvent.bind(this))
		document.addEventListener("facetrackingEvent", @onFaceTrackingEvent.bind(this))

	showVideo:()=>

		document.body.appendChild(@video)
		document.body.appendChild(@debugCanvas)

	hideVideo:()=>

		document.body.removeChild(@video)
		document.body.removeChild(@debugCanvas)



	onHeadTrackingEvent:(event)=>
		# console.log event

		# @marker.style.left = (document.body.clientWidth / 2 + event.x) + "px"
		# @marker.style.top = (document.body.clientHeight / 2 + event.y) + "px"

		# @emit("head", event)

	onFaceTrackingEvent:(event)=>
		# console.log event

		relX = (1 - (event.x / VIDEO_WIDTH))
		relY = (event.y / VIDEO_HEIGHT)

		# @marker.style.left = (document.body.clientWidth * relX) + "px"
		# @marker.style.top = (document.body.clientHeight * relY) + "px"

		# @appContainer.style.left = (document.body.clientWidth * ((1 - relX) - 0.5)) + "px"
		# @appContainer.style.top = (document.body.clientHeight * (( 1 - relY) - 0.5)) + "px"

		length = Math.sqrt(Math.pow(relX-0.5, 2) + Math.pow(relY-0.5,2))
		angle = Math.atan((relX - 0.5) / (relY - 0.5)) * (180 / Math.PI)

		# convert to svg angle space
		angle += 90
		if (relY < 0.5)
			angle *= -1
		else
			angle = Math.abs(angle - 180)

		# console.log "angle : #{angle} length : #{length}"

		if length > 0.05
			if @safeZone
				@emit("active", true)
				@safeZone = false
				console.log "Head control active"			
			@emit "angle", angle
		else
			if !@safeZone
				@emit("active", false)
				@safeZone = true
				console.log "Head control stop"

		@emit "x", relX

	start:()->

		if @started then return
		console.warn "Starting headtracker"
		@started = true
		@htracker.start()

	stop:()->

		if !@started then return
		@started = false
		console.warn "Stopping headtracker"
		@htracker.stop()







module.exports = Headtracker