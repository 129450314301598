Page = require "./Page.coffee"
Utils = require "../utils/Utils.coffee"

class CalibrationPage extends Page
	constructor:()->
		super

	init:(@id, @container)->

		@element = document.createElement("div")
		@element.classList.add "page"
		@element.setAttribute("id", @id)

		@marker = document.createElement("div")
		@marker.className = "calibrationMarker"
		@element.appendChild @marker

	showCenterMarker:()=>
		@marker.classList.add "center"

	showSideMarker:()=>
		@marker.classList.remove "center"
		@marker.classList.add "side"

	showTopMarker:()=>
		@marker.classList.remove "side"
		@marker.classList.add "top"

	calibrationFinished:()=>
		@marker.classList.remove "top"

	show:()=>
		super

		setTimeout(()=>
			@element.classList.add("visible")
		,500)

	hide:()=>
		
		@element.classList.remove("visible")

		setTimeout(()=>
			super
			@emit("hide", null)	
		,800)
		



module.exports = CalibrationPage