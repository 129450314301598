FxController = require "./FxController.coffee"
Utils = require "../utils/Utils.coffee"

NUM_LINES = 120
INNER_RADIUS = 200
LINE_SCALE = 5.5

class ShakeController extends FxController
	constructor:(@snap)->
		super

		@updateTimeoutId = -1
		@lastControllerPos = null

		@bar_timeout = 8 * 4 * 0.5 * 1000

		@useClick = true

	init:()->
		super

		# remove outer & inner circles
		@outerCircle.remove()
		@altCircle.remove()
		@timerGroup.remove()

		# add external lines
		@lines = []
		@lineGroup = @s.g()
		angleInc = 360.0 / NUM_LINES
		for i in [0..NUM_LINES-1] by 1
			lineStart = Utils.cartPolar i * angleInc, (INNER_RADIUS)
			lineEnd = Utils.cartPolar i * angleInc, INNER_RADIUS * LINE_SCALE

			pathString = "M #{500 + lineStart.x} #{500 + lineStart.y}"
			pathString += "L #{500 + lineEnd.x} #{500 + lineEnd.y}"

			newLine = @s.path(pathString).attr({ class : "shakeLine" })
			@lines.push newLine
			@lineGroup.add newLine

		#set class
		@s.addClass("shakeController")

		@shakeText = @s.text(500,500,"SHAKE").attr({ class : "fxLabel shake"} )
		@g.add @shakeText

		@shakeText.addClass("visible")

		@exitSegment.debugText.remove()

		# create 'drop' text
		@dropText = @s.text(@exitSegment.cX, @exitSegment.cY, "DROP")
		@dropText.prependTo(@exitSegment.iconElement)
		@dropText.attr({
			class : "exitLabel"
			})
		

	show:(@id, callback, autoExit=true, @useClick, @useColor=false)=>
		super


		if @indicatorGroup then @indicatorGroup.remove()

		document.body.classList.add("shake")
		@shakeText.addClass("visible")

		@twistText.removeClass("visible")
		@twistText.removeClass("attention")

		if @useColor
			for i in [0..@lines.length-1] by 1
				@lines[i].addClass "seed-" + Math.floor(Math.random() * 10)
				@lines[i].addClass "color-" + (i % 5)
		

	hide:(callback)=>
		super

		document.body.classList.remove("shake")

		

	showExit:()=>

		if @showingExit then return
		@showingExit = true

	
		@twistText.node.innerHTML = "click!"

		@s.node.addEventListener("click", ()=>
			@emit("exit", @id)
		)

		@shakeText.removeClass("visible")



		setTimeout(@showTwist, 500)
		

	setText:(text)=>
		@shakeText.node.innerHTML = text

	showWiggle:()=>
		@setText "WIGGLE"
		@gentlyMessage = false
		@on "intensityChange", (data)=>
			if data.intensity > 0.2 and not @gentlyMessage
				@setText "GENTLY"
				@gentlyMessage = true
				setTimeout(()=>
					@setText "SHAKE"
				,5000)

	showTwist:()=>
		if @twistText
			@twistText.addClass("visible")
			@twistText.addClass("attention")

	update:()=>
		# super

		if @intensity < 0.9
			@intensity *= 0.98
		if @intensity < 0.01
			@intensity = 0

		if @lines
			for i in [0..@lines.length-1] by 1

				if (Math.random() < @intensity)
					@lines[i].attr({
						"stroke-width" : 1
						"stroke-dasharray" : (Math.floor(Math.random() * (1 - @intensity) * 50) + 20)  + "px"
						})					
				else
					@lines[i].attr({
						"stroke-width" : 0
						})

		if @intensity > 0.5
			@shakeText.node.innerHTML = "SHAKE"

		if @intensity > 0.9 and @useClick
			@showExit()
		if @intensity <= 1.0
			@emit("intensityChange", { id : @id, intensity : @intensity })
		

		# console.log @intensity

	connectController:(@controller)=>
		super

		@controller.removeListener("gamma", @onControllerTwist)

	onControllerMove:(value)=>

		if not @visible then return

		if @lastControllerPos == null
			@lastControllerPos = value
			return

		@intensity += Math.abs(@lastControllerPos - value) * 0.2
		@intensity = Math.min(1.0, @intensity)
		@lastControllerPos = value

		# if @intensity > 0.96
		# 	@showExit()

		@emit("intensityChange", { id : @id, intensity : @intensity })

	onControllerTwist:(value)=>
		console.log "controller twist : #{value}"
		if value < -130 || value > 130
			@controller.removeListener("gamma", @onControllerTwist)
			@exitSegment.enable()
			@emit("exit", @id)

	onControllerClick:()=>
		if @showingExit then @emit "exit", @id

	onMouseMove:(event)=>

		MAX_MOVE = 120

		length = Math.sqrt(Math.pow(event.movementX, 2) + Math.pow(event.movementY, 2))
		

		length /= 120
		length = Math.min(1, length)

		@intensity += length * 0.02

		@intensity = Math.min(1, @intensity)



module.exports = ShakeController