ControllerBase = require "../base/ControllerBase.coffee"
Utils = require "../utils/Utils.coffee"

# everything relative to a 1000 x 1000 box inside viewport

OUTER_RADIUS = 300
INNER_RADIUS = 180

NUM_SHAPES = 20

TIMEBASE = 500

SHAPE_SIZE = 300

class WaitingVis extends ControllerBase
	constructor:(@snap)->

		@visible = false

		@updateCountdownTimeoutId = -1

	getNode:()->
		return @s.node

	init:()->


		@s = @snap.svg(0,0,"100%", "100%", 0,0,1000,1000);
		@s.attr({
			class : "waitingVis"
			})

		@removeFromDOM()

		@shapeLookup = [
			(x,y)=>
				return @s.rect(x-SHAPE_SIZE/2, y-SHAPE_SIZE/2, SHAPE_SIZE, SHAPE_SIZE)
			(x,y)=>
				return @createNgon(x,y, 6, SHAPE_SIZE)
			(x,y)=>
				return @s.circle(x,y, SHAPE_SIZE/2)
			(x,y)=>
				return @createNgon(x,y,4, SHAPE_SIZE, -90).addClass "triangle"
			(x,y)=>
				return @createNgon(x,y,8, SHAPE_SIZE, 22.5)
		]

		@element = @s.g()

		# @outerCircle = @s.circle(500,500,OUTER_RADIUS).attr({ fill : "#fff", class : "outerCircle" })
		# @innerCircle = @s.circle(500,500,OUTER_RADIUS).attr({ fill : "#fff", class : "innerCircle" })

		# @element.add @outerCircle
		# @element.add @innerCircle

		@shapeIndex = 0
		@shapes = []

		for i in [0..NUM_SHAPES-1] by 1

			type = "fill"
			idx = Math.random()
			if idx < 0.3 then type = "fill"
			else if idx < 0.6 then type = "stroke"
			else type = "spin"

			shapeIndex = Math.floor(Math.random() * @shapeLookup.length)
			newShape = @shapeLookup[shapeIndex](500,500)
			newShape.addClass "waitingShape"
			newShape.addClass "color-" + Math.floor(Math.random() * 4)
			newShape.addClass "seed-" + Math.floor(Math.random() * 6)
			newShape.addClass type
			@element.add newShape
			@shapes.push newShape
		
		@smallTimer = @s.circle(500,740, 10).attr({ class : "smallTimer" })

		# @element.attr({
		# 	mask : @maskGroup
		# })

		@textLabel = @s.text(500,500,"").attr({ class : "fxLabel" })
		@element.add @textLabel

		@countdownLabel = @s.text(500, 600, "").attr({ class : "fxLabel countdown"})
		@countdownIndex = 0

		bbox = @element.getBBox()
		@element.attr({
			transform: "s0,0," + bbox.cx + "," + bbox.cy
			})


	getNgonPoints:(numPoints, radius, angleOffset=0)=>
		points = []
		for i in [0..numPoints-1] by 1
			points.push Utils.cartPolar ((i/numPoints) * 360) + angleOffset, radius
		return points

	createNgon:(x,y, numPoints, radius, angleOffset=0)=>
		points = @getNgonPoints(numPoints, radius/2, angleOffset)
		pathString = "M #{x + points[0].x} #{y + points[0].y}"
		for i in [1..points.length-1] by 1
			pathString += "L #{x + points[i].x} #{y + points[i].y}"
		pathString += "Z"
		return @s.path(pathString)

	nextShape:()=>

		if @visible
			setTimeout(@nextShape, 500)

		@shapes[@shapeIndex].removeClass "animate"

		@shapeIndex++
		if @shapeIndex >= @shapes.length then @shapeIndex = 0

		@shapes[@shapeIndex].addClass "animate"

	showTimer:(time)=>
		@smallTimer.addClass "visible"
		@smallTimer.node.style.transitionDuration = time + "s, 0.5s"
		@smallTimer.node.style.transitionDelay = "0s, " + time + "s"
		@smallTimer.addClass "animate"

	hideTimer:()=>
		@smallTimer.removeClass "animate"
		@smallTimer.removeClass "visible"

	setText:(text, attention=false, className="")=>
		@textLabel.removeClass "visible"
		@textLabel.removeClass "attention"
		setTimeout(()=>
			@textLabel.node.innerHTML = text
			@textLabel.addClass "visible"
			if attention then @textLabel.addClass "attention"
		, 1000)
		if className then @textLabel.addClass className
		

	setCountdown:(seconds)=>
		@countdownLabel.addClass "visible"
		@countdownIndex = seconds
		clearTimeout(@updateCountdownTimeoutId)
		@updateCountdownTimeoutId = setTimeout(@updateCountdown, 1000)

	updateCountdown:()=>
		@countdownIndex--
		if @countdownIndex > 0
			@countdownLabel.node.innerHTML = @countdownIndex
			clearTimeout(@updateCountdownTimeoutId)
			@updateCountdownTimeoutId = setTimeout(@updateCountdown, 1000)
		else
			@countdownLabel.node.innerHTML = ""
			@countdownLabel.removeClass "visible"

	show:(callback)=>

		@addToDOM()

		@visible = true

		# console.log "Showing WaitingVis"

		@s.addClass("visible")

		@smallTimer.removeClass "animate"

		@nextShape()

		bbox = @element.getBBox()
		@element.animate( {
			transform : "s1,1," + bbox.cx + "," + bbox.cy
			}, 200, mina.easeIn, callback)

	hide:(callback)=>

		@visible = false

		@setText ""

		# console.log "Hiding WaitingVis"

		@smallTimer.removeClass "animate"

		for i in [0..@shapes.length-1] by 1
			@shapes[i].removeClass "animate"

		@s.removeClass("visible")

		setTimeout(()=>
			if !@visible
				@removeFromDOM()
		, 500)







module.exports = WaitingVis