Page = require "./Page.coffee"
Utils = require "../utils/Utils.coffee"

class AboutPage extends Page
	constructor:()->
		super


	onLoaded:(rq)=>
		super

		@exitButton = @element.querySelector(".exitButton")
		@exitButton.addEventListener("click", @hide)



	show:()=>
		super

		@element.classList.add("visible")

	hide:()=>
		super

		@element.classList.remove("visible")
		@emit("hide", null)



module.exports = AboutPage