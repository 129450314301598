Page = require "./Page.coffee"
Utils = require "../utils/Utils.coffee"

class LogoPage extends Page
	constructor:()->
		super

		@videoLoaded = false

	init:(@id, @container)->

		@element = document.createElement("div")
		@element.classList.add "page"
		@element.setAttribute("id", @id)

	show:(@video)=>
		super

		@element.appendChild @video
		@video.loop = false
		
		@video.addEventListener("ended", @hide)

		setTimeout(()=>
			@video.play()
			@element.classList.add("visible")
		,800)

	hide:()=>
		super
		@element.classList.remove("visible")
		@emit("hide", null)	



module.exports = LogoPage